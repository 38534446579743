import React, {Component} from 'react';
import {Link, Redirect, withRouter} from "react-router-dom";
import {
	addDays,
	createAlert,
	daysInMonth,
	devLog,
	formValidation,
	getApiLinkV2,
	getCountryCode,
	getCurrencies,
	getCurrency,
	getDayName,
	getLayoutImg,
	getLayoutName,
	getSource,
	getToken,
	getUser,
	hasTerm,
	nth,
	printAmenity,
	printDateForServer,
	printPrice,
	printPriceOnly,
	printPriceWithoutCurrencyName,
	printSpaceData,
	resetBookings,
	setSource,
	setSpaceBooking,
	updateEndTime,
	updateFullDayTime,
	updateHalfDayTime,
	updateStartTime,
} from "../../Helpers";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAngleLeft, faArrowLeft, faCircleNotch, faStar} from "@fortawesome/free-solid-svg-icons";
import {faCalendar} from "@fortawesome/free-regular-svg-icons";
import NavBar from "../atoms/NavBar";
import FloatingAlert from "../organisms/FloatingAlert";
import DatePicker from "react-datepicker";
import queryString from "query-string";
import "react-datepicker/dist/react-datepicker.css";
import axios from "axios";
import {getAxios} from "src/Helpers";
import Footer from "../organisms/Footer";
import Bugsnag from "@bugsnag/js";
import ReactGA from "react-ga-neo";
import {connect} from "react-redux";
import {CountryDropdown, RegionDropdown} from "react-country-region-selector";
import PhoneInput from "react-phone-input-2";
import 'react-phone-input-2/lib/bootstrap.css'
import {CATERED_AMENITIES, getTypeTextKey} from "../host/host-dashboard/manage-listings/add-edit-workspaces/consts";
import {PaymentVerification} from "../payment-verfication";
import libphonenumber from "google-libphonenumber";
import {FMPayment} from "../organisms/fm-payment";
import {Helmet} from 'react-helmet';


let phoneUtil = libphonenumber.PhoneNumberUtil.getInstance()
let PNF = libphonenumber.PhoneNumberFormat

const excludedDates = {};
//   'Mar 10, 2021': true,
//   'Mar 11, 2021': true,
//   'May 12, 2021': true,
//   'May 13, 2021': true,
//   'May 14, 2021': true,
//   'May 15, 2021': true,
//   'Jul 18, 2021': true,
//   'Jul 19, 2021': true,
//   'Jul 20, 2021': true,
//   'Jul 21, 2021': true,
//   'Jul 22, 2021': true,
//   'Aug 9, 2021': true,
//   'Aug 10, 2021': true,
//   'Oct 18, 2021': true,
//   'Oct 19, 2021': true,
//   'Nov 30, 2021': true,
//   'Dec 1, 2021': true,
//   'Dec 2, 2021': true,
// };

const week = [
	'Sun',
	'Mon',
	'Tue',
	'Wed',
	'Thu',
	'Fri',
	'Sat',
];

const fullWeek = [
	'Sunday',
	'Monday',
	'Tuesday',
	'Wednesday',
	'Thursday',
	'Friday',
	'Saturday',
];

const venues = [
	"Hotel 3-Star",
	"Hotel 4-Star",
	"Hotel 5-Star",
	"Events Venue",
	"Corporate Office",
	"Training Center",
	"Business Center/Hub",
	"Restaurants",
	"Office Spaces",
	"Others",
]

const layouts = [
	{id: 'u_shape_chk', name: 'U shape'},
	{id: 'boardroom_chk', name: 'Boardroom'},
	{id: 'reception_chk', name: 'Reception'},
	{id: 'banquet_chk', name: 'Banquet'},
	{id: 'theatre_chk', name: 'Theatre'},
	{id: 'classroom_chk', name: 'Classroom'},
]

const amenities = [
	'Wifi',
	'Projector & Screen',
	'LCD Screen',
	'Flipchart',
	'Mic',
	'AV (Sound) System',
]

const foodMenu = [
	{
		key: 'breakfast', name: 'Breakfast',
		options: [
			{name: 'Breakfast buffet'},
			{name: 'Pastries & other sweet assortments'},
			{name: 'Savory breakfast sandwiches'},
			{name: 'Fruit platter'},
			{name: 'Other'}
		],
	}, {
		key: 'tea-coffee', name: 'Tea & Coffee Break',
		options: [
			{name: 'Tea & coffee only'},
			{name: 'Tea & coffee + biscuits'},
			{name: 'Tea & coffee + biscuits & pastries'},
			{name: 'Tea & Coffee + Pastries & fruits'},
			{name: 'High tea (tea & coffee with biscuits, pastries, light sandwiches)'},
			{name: 'Tea & coffee with other'}
		]
	}, {
		key: 'lunch', name: 'Lunch',
		type: [
			{name: 'Arabic'},
			{name: 'Indian'},
			{name: 'Pakistani'},
			{name: 'Filipino'},
			{name: 'Mediterranean'},
			{name: 'International'},
		],
		lunch: [
			{name: 'Vegetarian Buffet'},
			{name: 'Buffet (includes vegetarian & non-vegetarian dishes)'},
			{name: 'Sandwiches & other light assortments'},
			{name: 'Canapes (finger foods)'},
			{name: 'Kids Menu (finger foods)'},
			{name: 'Other'}
		],
		drinks: [
			{name: 'Water & Soft Drinks'},
			{name: 'Water & Juices'},
			{name: 'Water & Alcoholic Beverages'},
			{name: 'Other'}
		],
	}, {
		key: 'dinner', name: 'Dinner',
		type: [
			{name: 'Arabic'},
			{name: 'Indian'},
			{name: 'Pakistani'},
			{name: 'Filipino'},
			{name: 'Mediterranean'},
			{name: 'International'},
		],
		lunch: [
			{name: 'Vegetarian Buffet'},
			{name: 'Buffet (includes vegetarian & non-vegetarian dishes)'},
			{name: 'Sandwiches & other light assortments'},
			{name: 'Canapes (finger foods)'},
			{name: 'Kids Menu (finger foods)'},
			{name: 'Other'}
		],
		drinks: [
			{name: 'Water & Soft Drinks'},
			{name: 'Water & Juices'},
			{name: 'Water & Alcoholic Beverages'},
			{name: 'Other'}
		],
	}, {
		key: 'drinks', name: 'Drinks Reception', message: ''
	},
];

const dateOptions = { year: 'numeric', month: 'short', day: 'numeric' };

class WorkspaceBooking extends Component {
	
	oldScroll = 0;
	stickDiv = null;
	slideDiv = null;
	bottomDiv = null;
	
	constructor(props) {
		super(props);
		
		var initState = {
			space: (props.generic) ? null : {},
			page: (props.page) ? props.page : 'event',
			term: (props.term) ? props.term : 'catered',
			food: null,
			service: null,
			selectedTerm: 'hourly',
			selectedLayout: '',
			selectedCountry: this.props.location?.country ? this.props.location.country : '',
			selectedCity: this.props.location?.city ? this.props.location.city : '',
			selectedLayoutMax: 0,
			selectedAmenities: {},
			flexibleDates: true,
			accomReq: false,
			sendToOthers: true,
			directContact: true,
			isRecurring: false,
			capacity: 1,
			startTime: "",
			endTime: "",
			time: "",
			recurringType: 'weekly',
			dayIndex: 0,
			copyAlert: 0,
			recurringNum: 0,
			days: [],
			rooms: [],
			request: {},
			recurring: {},
			isScrolled: false,
			isBottomReached: false,
			reqLoading: false,
			coupon: '',
			couponType: '',
			couponPrice: 0,
			showCoupon: false,
			couponLoading: false,
			startDate: null,
			endDate: null,
			accomStartDate: null,
			accomEndDate: null,
			countryCode: null,
			excludedDays: {},
			errors: {},
			persons: 0,
			loadingSelecCities: false,
			selectCities: [
				"Abu Dhabi",
				"Ajman",
				"Dubai",
				"Fujairah",
				"Ras Al Khaimah",
				"Sharjah",
				"Umm Al Quwain"
			]
		}
		
		if (props.location.state) {
			
			const {space, term, food, service, source} = props.location.state;
			
			initState.space = space;
			initState.term = term;
			initState.food = food;
			initState.service = service;
			
			if (source) setSource(source);
			
		} else {
			
			let search = queryString.parse(props.location.search);
			if (search && search.source) {
				setSource(search.source);
			}
		}
		
		this.state = initState;
	}
	
	componentDidUpdate(prevProps, prevState, snapshot) {
		if (this.props.location.pathname !== prevProps.location.pathname) {
			window.scrollTo(0, 0);
			this.setState({page: this.props.match.params.page}, function () {
				this.initProgress();
			});
		}
	}
	
	componentDidMount() {
		if(this.props.location) {
			if(this.props.location.country) {
				this.setState({loadingSelecCities: true, selectedCountry: this.props.location.country, selectedCity: this.props.location.city});
				const url = getApiLinkV2(`city?search=${this.props.location.country}`);
				getAxios().get(url).then(response => {
					console.log(response.data)
					if (response.data.cities) {
						let cities = [];
						Object.keys(response.data.cities).forEach(key => {
							let item = response.data.cities[key];
							cities.push(item.c_name);
						});
						let uniqueCities = new Set(cities);
						uniqueCities = Array.from(uniqueCities).filter(c => c !== "دبي");
						this.setState({selectCities: uniqueCities});
						this.setState({loadingSelecCities: false});
					}
				}).catch(error => {
					console.log(error);
					this.setState({loadingSelecCities: false});
				});
			}
		}
		
		document.body.classList.remove("no-scroll");
		
		const datePickers = document.getElementsByClassName("react-datepicker__input-container");
		Array.from(datePickers).forEach((el => el.childNodes[0].setAttribute("readOnly", true)));
		
		const {generic, location} = this.props;
		const {space, term} = this.state;
		
		if (space || generic) {
			
			if (space && Object.keys(space).length === 0 && !generic) {
				this.fetchSpace();
				return;
			}
			
			if (term === 'hourly') {
				const user = getUser();
				if (user && !this.props.location?.country && !this.props.location?.city) {
					this.setState({selectedCountry: user.country, selectedCity: user.city});
				}
			}
			
			this.initProgress();
			this.initDate();
			
			if (!generic) {
				this.initPlans();
			}
		}
		
		if (term === 'hourly') {
			window.addEventListener('scroll', this.handleScroll);
		} else {
			
			this.interval = setInterval(() => {
				if (document.querySelector('#space-stick')) {
					document.querySelector('#space-stick').style.maxHeight = (window.innerHeight - 400) + 'px';
				}
				
			}, 1000);
			
			
		}
		window.addEventListener('beforeunload', this.handleUnLoad);
		
		if (term === 'hourly') {
			const spaceType = location.pathname.indexOf('st-office') !== -1 ? 'Office Space' : location.pathname.indexOf('st-banquet') !== -1 ? 'Event' +
				' Space' : location.pathname.indexOf('st-coworking') !== -1 ? 'Co-Working Space' : 'Meeting Room';
			ReactGA.event({
				category: 'ST',
				action: 'ST_Attempt',
				label: spaceType + ' ST Booking Attempt for space ' + space.workspace_id
			});
		} else {
			const source = getSource();
			const spaceType = location.pathname.indexOf('catered-banquet') !== -1 ? 'Event Space' : 'Meeting Room';
			var eventLabel = spaceType + ' Catered Attempt from ' + source;
			if (!generic) eventLabel = eventLabel + ' for space ' + space.workspace_id;
			ReactGA.event({
				category: 'DDR',
				action: 'DDR_Attempt' + (generic ? '_Generic' : '_Unique'),
				label: eventLabel
			});
		}
		//ReactGA.pageview(window.location.pathname + window.location.search);
		
		if (term === 'hourly') {
			if (space && space.layouts && Object.keys(space.layouts).length === 1) {
				const key = Object.keys(space.layouts)[0];
				this.setState({selectedLayout: key, selectedLayoutMax: space.layouts[key]})
			}
		}
	}
	
	componentWillUnmount() {
		
		const {term} = this.state;
		if (term === 'hourly') {
			window.removeEventListener('scroll', this.handleScroll);
		}
		window.removeEventListener('beforeunload', this.handleUnLoad);
	}
	
	fetchSpace = () => {
		
		const {match, term} = this.props;
		
		const url = getApiLinkV2('workspace/' + match.params.id);
		const token = getToken();
		const headers = {'Content-Type': 'application/json'};
		if (token) {
			headers['Authorization'] = 'Bearer ' + token;
		}
		
		getAxios().get(url, {
			headers: headers,
		}).then(response => {
			if (response.data.workspace) {
				
				devLog(response.data.workspace);
				const space = response.data.workspace;
				const defSpaceData = printSpaceData(space.workspace_type_id);
				if (hasTerm(space, term)) {
					
					this.setState({
						space,
						similarSpaces: response.data.similar_workspace,
						food: defSpaceData.food,
						service: defSpaceData.service,
					}, function () {
						this.initProgress();
						this.initDate();
					});
					
					if (term === 'hourly') {
						if (space && space.layouts && Object.keys(space.layouts).length === 1) {
							const key = Object.keys(space.layouts)[0];
							this.setState({selectedLayout: key, selectedLayoutMax: space.layouts[key]})
						}
					}
					
				} else {
					this.setState({space: null});
				}
			}
		}).catch(error => {
			Bugsnag.notify(error);
			console.error((error.response && error.response.data.error) ? error.response.data.error : error.message);
			this.setState({space: null});
		});
	};
	
	handleExitBooking = () => {
		
		const {match, history, generic} = this.props;
		const {term} = this.state;
		
		if (window.confirm('Exit booking? Changes you made will not be saved.')) {
			history.push((generic) ? '/' : '/space/' + match.params.id + '?t=' + term);
		}
	};
	
	handleUnLoad = event => {
		const message = 'Exit booking? Changes you made will not be saved.';
		event.preventDefault();
		event.returnValue = message;
		return message;
	};
	
	handleScroll = event => {
		
		if (window.innerWidth < 768) return;
		
		if (!this.slideDiv) {
			this.slideDiv = document.querySelector("#slide-cont");
			if (!this.slideDiv) return;
		}
		if (!this.stickDiv) {
			this.stickDiv = document.querySelector("#space-stick");
			if (!this.stickDiv) return;
		}
		
		const isScrollingDown = window.scrollY > this.oldScroll;
		const dist = isScrollingDown ? 210 : 190;
		
		if (!this.slideDiv.classList.contains("sticky")) {
			this.slideDiv.classList.add("sticky");
			this.slideDiv.style = 'top: 80px';
		}
		
		if (!this.bottomDiv) {
			this.bottomDiv = document.querySelector("#bottom-cont");
			if (!this.bottomDiv) return;
		}
		
		if (window.scrollY > (this.bottomDiv.offsetTop - this.stickDiv.offsetHeight - dist)) {
			if (this.slideDiv.classList.contains("sticky")) {
				this.slideDiv.classList.remove("sticky");
				this.slideDiv.style = 'top: ' + (this.bottomDiv.offsetTop - this.stickDiv.offsetHeight - 120) + 'px';
			}
		} else {
			if (!this.slideDiv.classList.contains("sticky")) {
				this.slideDiv.classList.add("sticky");
				this.slideDiv.style = 'top: 80px';
			}
		}
		
		this.oldScroll = window.scrollY;
	};
	
	handleUpdateCapacity = e => {
		
		const {space, selectedLayoutMax} = this.state;
		const finalMax = selectedLayoutMax > 0 ? selectedLayoutMax : ((space.capacity > 0) ? space.capacity : 1);
		const newCapacity = parseInt(e.target.value);
		
		if (newCapacity > finalMax) {
			this.setState({capacity: finalMax});
			return;
		}
		if (newCapacity < 1) {
			this.setState({capacity: 1});
			return;
		}
		this.setState({capacity: newCapacity});
	};
	
	handleChooseLayout = (dayIndex, layout, max) => {
		
		const {days} = this.state;
		const day = days[dayIndex];
		day.selectedLayout = layout;
		day.selectedLayoutMax = max;
		this.setState({days: days});
	};
	
	onDayStartTimeChange = () => {
		
		const {days, dayIndex} = this.state;
		const day = days[dayIndex];
		const startTime = document.getElementById("start_time").value;
		day.startTime = startTime;
		this.setState({days: days});
		this.updateSelectedTimes(null, null, startTime);
	};
	
	onDayEndTimeChange = () => {
		
		const {days, dayIndex} = this.state;
		const day = days[dayIndex];
		day.endTime = document.getElementById("end_time").value;
		this.setState({days: days});
	};
	
	handleCopyDay = () => {
		
		const {days, dayIndex} = this.state;
		const dayBefore = days[dayIndex - 1];
		days[dayIndex] = JSON.parse(JSON.stringify(dayBefore));
		this.setState({days: days, copyAlert: dayIndex + 1});
	};
	
	handleUpdateDayCapacity = (e, dayIndex) => {
		
		const {days} = this.state;
		const day = days[dayIndex];
		const finalMax = day.selectedLayoutMax ? day.selectedLayoutMax : 2000;
		const newCapacity = parseInt(e.target.value);
		
		if (newCapacity > finalMax) {
			// TODO : Show error exceeded max number
			day.capacity = finalMax;
		} else if (newCapacity < 1) {
			day.capacity = 1;
		} else {
			day.capacity = newCapacity;
		}
		this.setState({days: days});
	};
	
	handleChooseDayAmenity = (e, dayIndex, amenity) => {
		
		const {days, space} = this.state;
		devLog('space', space)
		const day = days[dayIndex];
		var amenities = day.selectedAmenities;
		if (e) {
			const count = parseInt(e.target.value);
			if (count > 0) {
				amenities[amenity] = count;
			} else if (e.target.value === '') {
				amenities[amenity] = '';
			} else {
				delete amenities[amenity];
			}
		} else {
			if (amenities[amenity]) {
				delete amenities[amenity];
			} else {
				amenities[amenity] = 1;
			}
		}
		this.setState({days: days});
	};
	
	handleChooseDayFood = (dayIndex, key) => {
		
		const {days} = this.state;
		const day = days[dayIndex];
		var food = day.selectedFood;
		if (food[key]) {
			delete food[key];
		} else {
			switch (key) {
				case 'breakfast':
					food[key] = {selectedOption: '', optionOther: ''};
					break;
				case 'tea-coffee':
					food[key] = {selectedOption: '', optionOther: ''};
					break;
				case 'lunch':
					food[key] = {
						selectedType: '',
						selectedLunch: '',
						selectedDrink: '',
						lunchOther: '',
						drinkOther: ''
					};
					break;
				case 'dinner':
					food[key] = {
						selectedType: '',
						selectedLunch: '',
						selectedDrink: '',
						lunchOther: '',
						drinkOther: ''
					};
					break;
				case 'drinks':
					food[key] = {optionOther: ''};
					break;
				default:
					break;
			}
		}
		this.setState({days: days});
	};
	
	handleChooseDayFoodOption = (dayIndex, key, optionType, value) => {
		
		const {days} = this.state;
		const day = days[dayIndex];
		var food = day.selectedFood;
		
		switch (key) {
			case 'breakfast':
			case 'tea-coffee':
				food[key].selectedOption = value;
				food[key].optionOther = null;
				break;
			case 'lunch':
			case 'dinner':
				if (optionType === 'type') {
					food[key].selectedType = value;
				} else if (optionType === 'lunch') {
					food[key].selectedLunch = value;
				} else if (optionType === 'drinks') {
					food[key].selectedDrink = value;
				}
				food[key].lunchOther = null;
				food[key].drinkOther = null;
				break;
			default:
				break;
		}
		this.setState({days: days});
	};
	
	handleChooseVenue = venue => {
		
		const {request} = this.state;
		const selectedVenues = (request.selectedVenues) ? request.selectedVenues : {};
		
		if (selectedVenues[venue]) {
			delete selectedVenues[venue];
		} else {
			selectedVenues[venue] = true;
		}
		request.selectedVenues = selectedVenues;
		this.setState({request: request});
	};
	
	handleChooseAmenity = amenity => {
		devLog('st', amenity);
		var amenities = this.state.selectedAmenities;
		if (amenities[amenity]) {
			delete amenities[amenity];
		} else {
			amenities[amenity] = true;
		}
		this.setState({selectedAmenities: amenities});
	};
	
	printTimeOptions = (space, selectedTerm, startDate, type = null, startTime = null) => {
		
		if (!startDate) return (<></>);
		
		const dayIndex = startDate.getDay();
		var day = '';
		if (dayIndex === 0) {
			day = 'sunday';
		} else if (dayIndex === 1) {
			day = 'monday';
		} else if (dayIndex === 2) {
			day = 'tuesday';
		} else if (dayIndex === 3) {
			day = 'wednesday';
		} else if (dayIndex === 4) {
			day = 'thursday';
		} else if (dayIndex === 5) {
			day = 'friday';
		} else if (dayIndex === 6) {
			day = 'saturday';
		}
		
		if (selectedTerm === 'hourly') {
			
			if (space) {
				if (type === "end") {
					const minHoursDiff = (this.state.term === 'hourly' && space.min_booking_hrs) ? parseInt(space.min_booking_hrs) : 1;
					return updateEndTime(space.ws_avail, day, startTime, minHoursDiff);
				} else {
					return updateStartTime(space.ws_avail, day);
				}
			} else {
				if (type === "end") {
					return updateEndTime(null, day, startTime, 1);
				} else {
					return updateStartTime(null, day);
				}
			}
			
		} else if (selectedTerm === 'half_day') {
			
			return updateHalfDayTime(space.ws_avail, day);
			
		} else if (selectedTerm === 'day') {
			
			return updateFullDayTime(space.ws_avail, day);
		}
	};
	
	printPriceDetails = (space, coupon, couponType, couponPrice, selectedTerm, startDate, startTime, endTime, isRecurring, recurring, recurringType, recurringNum, currency) => {
		
		if (!startDate) return (<></>);
		
		const day = getDayName(startDate);
		const vat = 5;
		const transaction_fee = (space.user_transaction_fee) ? space.user_transaction_fee : 5;
		var price = (selectedTerm === 'day') ? parseFloat(space.day_price) : (selectedTerm === 'half_day') ? parseFloat(space.half_day_price) : (selectedTerm === 'hourly') ? parseFloat(space.hour_price) : 0;
		
		if (space.exception_rules === 'yes') {
			if (space.exception_day1 === day) {
				price = (selectedTerm === 'day') ? parseFloat(space.exception_day_price1) : (selectedTerm === 'half_day') ? parseFloat(space.exception_half_day_price1) : (selectedTerm === 'hourly') ? parseFloat(space.exception_hour_price1) : 0;
			} else if (space.exception_day2 === day) {
				price = (selectedTerm === 'day') ? parseFloat(space.exception_day_price2) : (selectedTerm === 'half_day') ? parseFloat(space.exception_half_day_price2) : (selectedTerm === 'hourly') ? parseFloat(space.exception_hour_price2) : 0;
			}
		}
		
		const start_hr = (startTime) ? parseInt(startTime.substr(0, 2)) : 0;
		const end_hr = (endTime) ? parseInt(endTime.substr(0, 2)) : 0;
		const finalRecurringNum = (recurringNum > 0) ? recurringNum : 1;
		const bookingCount = (isRecurring) ? ((recurringType === 'monthly') ? finalRecurringNum : (Object.keys(recurring).length) * finalRecurringNum) : 1;
		
		const timeDiff = Math.ceil(end_hr - start_hr);
		
		price = (selectedTerm === 'hourly' && timeDiff > 0) ? timeDiff * price : price;
		
		if (isRecurring) {
			price = price * bookingCount;
		}
		
		var elements = [];
		
		elements.push(
			<div className="col-12 pr-0 pl-0">
				<div className="booking-price">
					<div className="b-title">
						{(selectedTerm === 'day') ? '1 day' : (selectedTerm === 'half_day') ? 'half day' : (selectedTerm === 'hourly' && timeDiff > 0) ? timeDiff + ' hour' : '-'}
						{(space.exception_rules === 'yes' && (space.exception_day1 === day || space.exception_day2 === day) && ('(' + day + ' Exception price)'))}
						{(bookingCount > 1) ? ' x ' + (bookingCount) + ' times' : ''}
					</div>
					<div className="b-price">
						{printPrice(price, space.currency, currency.name)}
					</div>
				</div>
			</div>
		);
		elements.push(
			<div className="col-12 pr-0 pl-0">
				<div className="booking-price">
					<div className="b-title">VAT {vat}%</div>
					<div className="b-price">
						{printPrice((price * (vat / 100)), space.currency, currency.name)}
					</div>
				</div>
			</div>
		);
		
		price = price + (price * (vat / 100));
		
		elements.push(
			<div className="col-12 pr-0 pl-0">
				<div className="booking-price">
					<div className="b-title total-price">Subtotal</div>
					<div className="b-price total-price">
						{printPrice(price, space.currency, currency.name)}
					</div>
				</div>
			</div>
		);
		
		price = price + (price * (transaction_fee / 100));
		
		elements.push(
			<div className="col-12 pr-0 pl-0">
				<div className="booking-price">
					<div className="b-title">User transaction Fee {transaction_fee}%</div>
					<div className="b-price">
						{printPrice((price * (transaction_fee / 100)), space.currency, currency.name)}
					</div>
				</div>
			</div>
		);
		
		if (coupon !== '' && couponType !== '' && couponPrice !== 0) {
			elements.push(
				<div className="col-12 pr-0 pl-0">
					<div className="booking-price">
						<div className="b-title total-price">Price</div>
						<div className="b-price total-price line-through">
							{printPrice(price, space.currency, currency.name)}
						</div>
					</div>
				</div>
			);
			elements.push(
				<div className="col-12 pr-0 pl-0">
					<div className="booking-price">
						<div
							className="b-title">Discount {(couponType === 'percent') ? couponPrice + '%' : '-' + (printPrice(couponPrice, space.currency, currency.name))}</div>
						<div className="b-price">
							- {printPrice(((couponType === 'percent') ? (price * (couponPrice / 100)) : couponPrice), space.currency, currency.name)}
						</div>
					</div>
				</div>
			);
			
			price = price - (printPriceOnly(((couponType === 'percent') ? (price * (couponPrice / 100)) : couponPrice), '', ''));
			
			elements.push(
				<div className="col-12 pr-0 pl-0">
					<div className="booking-price">
						<div className="b-title total-price">Total price</div>
						<div className="b-price total-price">
							{printPrice(price, space.currency, currency.name)}
						</div>
					</div>
				</div>
			);
			
		} else {
			elements.push(
				<div className="col-12 pr-0 pl-0">
					<div className="booking-price">
						<div className="b-title total-price">Total price</div>
						<div className="b-price total-price">
							{printPrice(price, space.currency, currency.name)}
						</div>
					</div>
				</div>
			);
		}
		
		return elements;
	};
	
	printRequestSummary = (space, page, request) => {
		
		var elements = [];
		
		// if (page === 'contact') return elements;
		
		elements.push(
			// <div className="col-12 pt-2 pr-0 pl-0">
			// 	<div className="b-title total-price mb-1">Contact info</div>
			// 	{request.fName + " " + request.lName + ", "}
			// 	{request.email + ", "}
			// 	{request.phone + ", "}
			// 	{request.company}
			// </div>
		);
		
		// if (page === 'event') return elements;
		
		elements.push(
			<div className="col-12 pt-3 pr-0 pl-0">
				<div className="b-title total-price mb-1">Event info</div>
				{(request.eventCity) ? request.eventCity + ", " : ""}
				{request.eventType}{", "}
				{(request.selectedVenues && (request.selectedVenues && venues.map((item => {
						if (!request.selectedVenues[item]) return '';
						return item + ', '
					}))
				))}
				{(request.startDate && request.endDate && (
					<>
						{(request.startDate === request.endDate) ? request.startDate.toLocaleDateString("en-US", dateOptions) : request.startDate.toLocaleDateString("en-US", dateOptions) + ' to ' + request.endDate.toLocaleDateString("en-US", dateOptions)} {" "}
						{"(" + ((request.flexibleDates) ? ((request.startDate === request.endDate) ? 'Flexible Date' : 'Flexible Dates') : ((request.startDate === request.endDate) ? 'Non-Flexible Date' : 'Non-Flexible Dats')) + ")"}
					</>
				))}
			</div>
		);
		
		if (page === 'event-room') return elements;
		
		elements.push(
			<div className="col-12 pt-3 pr-0 pl-0">
				<div className="b-title total-price mb-1">Event space</div>
				{request.days.map((day, i) => (
					<>
						<div className="b-title b-sumlabel pt-1 mb-1">Day {i + 1}</div>
						{getLayoutName(day.selectedLayout)}{", "}
						{day.capacity + " people, "}
						{day.startTime.substr(0, 5) + ' to ' + day.endTime.substr(0, 5)}{", "}
						
						{day.selectedFood && foodMenu.map(item => {
							const selectedFood = day.selectedFood[item.key];
							if (!selectedFood) return '';
							return (
								<>
									<span className="semi-bold pr-1">{item.name}{": "}</span>
									{(selectedFood.selectedOption && (selectedFood.selectedOption + ", "))}
									{(selectedFood.selectedType && (selectedFood.selectedType + ", "))}
									{(selectedFood.selectedType && (selectedFood.selectedLunch + ", "))}
									{(selectedFood.selectedDrink && (selectedFood.selectedDrink + ", "))}
									{(item.name === 'Drinks Reception' && ('Other'))}
								</>
							)
						})}
						
						{(space ? (
							day.selectedAmenities && Object.keys(space.amenity).map((key, i) => {
								const item = space.amenity[key];
								const amenityCount = day.selectedAmenities[item.amenity];
								if (!amenityCount) return '';
								const amenity = printAmenity(item.amenity);
								if (!amenity) return '';
								return amenityCount + ' ' + amenity.name + ' required, '
							})
						) : (
							day.selectedAmenities && amenities.map(item => {
								const amenityCount = day.selectedAmenities[item];
								if (!amenityCount) return '';
								const amenity = printAmenity(item);
								if (!amenity) return '';
								return amenityCount + ' ' + amenity.name + ' required, '
							})
						))}
					</>
				))}
			</div>
		);
		
		if (page === 'accommodation') return elements;
		
		elements.push(
			<div className="col-12 pt-3 pr-0 pl-0 f-16">
				<div className="b-title total-price mb-1">Accommodation</div>
				{(request.accomReq) ?
					request.rooms.map((room, i) => (
						<>
							<div className="b-title b-sumlabel pt-1 mb-1">
								{addDays(request.accomStartDate, i).toLocaleDateString("en-US", dateOptions)}
							</div>
							{(room.single_room && room.single_room > 0 && (room.single_room + ' single ' + ((room.single_room > 1) ? 'rooms' : 'room')))}
							{(room.double_room && (((room.single_room && room.single_room > 0) ? ', ' : '') + room.double_room + ' double ' + ((room.double_room > 1) ? 'rooms' : 'room')))}
							{(room.twin_room && ((((room.single_room && room.single_room > 0) || (room.double_room && room.double_room > 0)) ? ', ' : '') + room.twin_room + ' twin ' + ((room.twin_room > 1) ? 'rooms' : 'room')))}
							{(((room.single_room && room.single_room > 0) || (room.double_room && room.double_room > 0) || (room.twin_room && room.twin_room > 0)) && (' required'))}
							{((room.chk_bed_breakfast) && (' (Breakfast included)'))}
						</>
					))
					: ('Not required')}
			</div>
		);
		
		if (page === 'price') return elements;
		
		elements.push(
			<div className="col-12 pt-3 pr-0 pl-0">
				<div className="b-title total-price mb-1">Price</div>
				{request.priceType + ': ' + request.price + ' ' + request.currency}
				{(space) ? ((request.sendToOthers) ? ' (Send request to other similar venues)' : '(Do not send request to other similar venues)') : ''}
				{/*{(space) ? ((request.directContact) ? ' (Allow to be directly contacted by the venues)' : '(Do not allow to be directly contacted by the venues)') : ''}*/}
			</div>
		);

		if (page === 'contact') return elements;

		return elements;
	};
	
	printRooms = (rooms, startDate, endDate, errors) => {
		
		var elements = [];
		
		if (!startDate || !endDate || rooms.length < 1) return (<></>);
		
		rooms.forEach((room, i) => {
			const finalDate = new Date();
			finalDate.setDate(startDate.getDate() + i);
			elements.push(
				<h6 className="ml-2 mt-4">
					{finalDate.toLocaleDateString("en-US", dateOptions)}
				</h6>
			);
			elements.push(
				<div className="dropdown-item opt pr-0 pl-0">
					<input className="form-check-input check-input"
					       type="checkbox" checked={room.single_room != null}
					       onChange={() => this.handleRoomNum(null, i, 'single_room')}/>
					<span className="opt-name"
					      onClick={() => this.handleRoomNum(null, i, 'single_room')}>Single room</span>
					{(room.single_room != null && (
						<div id="count" className="input-col">
							<input type="number" className="form-control" placeholder="1"
							       value={(room.single_room > 0) ? room.single_room : ''}
							       onChange={(e) => this.handleRoomNum(e, i, 'single_room')}
								   onWheel={ event => event.currentTarget.blur() }
								   />
						</div>
					))}
				</div>
			);
			elements.push(
				<div className="dropdown-item opt pr-0 pl-0">
					<input className="form-check-input check-input"
					       type="checkbox" checked={room.double_room != null}
					       onChange={() => this.handleRoomNum(null, i, 'double_room')}/>
					<span className="opt-name"
					      onClick={() => this.handleRoomNum(null, i, 'double_room')}>Double room</span>
					{(room.double_room != null && (
						<div id="count" className="input-col">
							<input type="number" className="form-control" placeholder="1"
							       value={(room.double_room > 0) ? room.double_room : ''}
							       onChange={(e) => this.handleRoomNum(e, i, 'double_room')}
								   onWheel={ event => event.currentTarget.blur() }
								   />
						</div>
					))}
				</div>
			);
			elements.push(
				<div className="dropdown-item opt pr-0 pl-0">
					<input className="form-check-input check-input"
					       type="checkbox" checked={room.twin_room != null}
					       onChange={() => this.handleRoomNum(null, i, 'twin_room')}/>
					<span className="opt-name" onClick={() => this.handleRoomNum(null, i, 'twin_room')}>Twin room</span>
					{(room.twin_room != null && (
						<div id="count" className="input-col">
							<input type="number" className="form-control" placeholder="1"
							       value={(room.twin_room > 0) ? room.twin_room : ''}
							       onChange={(e) => this.handleRoomNum(e, i, 'twin_room')}
								   onWheel={ event => event.currentTarget.blur() }
								   />
						</div>
					))}
				</div>
			);
			elements.push(
				<div className="dropdown-item opt pr-0 pl-0">
					<input className="form-check-input check-input" type="checkbox"
					       checked={room.chk_bed_breakfast != null}
					       onChange={() => this.handleRoomNum(null, i, 'chk_bed_breakfast')}/>
					<span className="opt-name" onClick={() => this.handleRoomNum(null, i, 'chk_bed_breakfast')}>Include breakfast</span>
				</div>
			);
			if (errors["room-" + i]) {
				elements.push(<div className="form-error mt-0">{errors["room-" + i]}</div>);
			}
		});
		return elements;
	};
	
	handleDateChangeRaw = e => {
		e.preventDefault();
	};
	
	toggleAccommodation = accomReq => {
		
		if (accomReq) {
			const {startDate} = this.state;
			const nextDate = addDays(startDate, 1);
			const rooms = [{}];
			this.setState({accomReq: true, accomStartDate: startDate, accomEndDate: nextDate, rooms: rooms,});
			return;
		}
		this.setState({accomReq: false});
	};
	
	handleRoomNum = (e, index, type) => {
		
		const {rooms} = this.state;
		const room = rooms[index];
		
		if (e == null) {
			if (room[type]) {
				delete room[type];
			} else {
				room[type] = 1;
			}
		} else {
			const count = parseInt(e.target.value);
			if (count > 0) {
				room[type] = count;
			} else {
				room[type] = 0;
			}
		}
		this.setState({rooms: rooms});
	};
	
	printRecurring = (startDate, recurring, recurringType, recurringNum) => {
		
		const finalType = (recurringType === 'monthly') ? 'month' : 'week';
		const finalCount = (recurringNum > 0) ? recurringNum : 1;
		const finalTime = (finalCount > 1) ? 'times' : 'time';
		var selectedDays = '';
		if (recurringType === 'weekly') {
			week.forEach((day, i) => {
				if (recurring[day]) selectedDays += fullWeek[i] + ', ';
			});
		}
		selectedDays = selectedDays.substr(0, selectedDays.length - 2);
		
		const finalRecurring = (recurringType === 'monthly') ? ((recurring.monthly === 1) ? daysInMonth(startDate) : startDate.getDate() + nth(startDate.getDate())) : selectedDays;
		
		return 'Every ' + finalType + ' on ' + finalRecurring + ' for ' + finalCount + ' ' + finalTime;
	};
	
	toggleRecurring = isRecurring => {
		
		if (isRecurring) {
			
			const {startDate} = this.state;
			var recurring = [];
			const day = week[startDate.getDay()];
			recurring[day] = true;
			
			this.setState({isRecurring: true, recurring: recurring, recurringNum: 10, recurringType: 'weekly'});
			
		} else {
			
			this.setState({isRecurring: false, recurring: {}, recurringNum: 0, recurringType: 'weekly'})
		}
	};
	
	handleChooseRecurringType = e => {
		
		const {startDate} = this.state;
		var recurring = this.state.recurring;
		
		const type = e.target.value;
		if (type === 'monthly') {
			recurring = {monthly: 0};
		} else {
			delete recurring.monthly;
			const day = week[startDate.getDay()];
			recurring[day] = true;
		}
		this.setState({recurringType: type, recurring: recurring});
	};
	
	handleChooseRecurring = day => {
		
		const {startDate, recurringType} = this.state;
		var recurring = this.state.recurring;
		
		if (recurringType === 'monthly') {
			recurring = {monthly: day};
		} else {
			delete recurring.monthly;
			if (recurring[day]) {
				delete recurring[day];
				if (Object.keys(recurring).length < 1) {
					const day = week[startDate.getDay()];
					recurring[day] = true;
				}
			} else {
				recurring[day] = true;
			}
		}
		this.setState({recurring: recurring});
	};
	
	handleBackBtn = () => {
		
		const {match, location, history, generic} = this.props;
		const {page, days, dayIndex} = this.state;
		const spaceType = location.pathname.indexOf('catered-meeting') !== -1 ? 'catered-meeting' : 'catered-banquet';
		const baseUrl = (generic) ? '/request/' + spaceType + '/generic' : '/request/' + spaceType + '/' + match.params.id;
		
		switch (page) {
			case 'contact':
				history.push(baseUrl + '/price');
				break;
			case 'event-room':
				if (days.length > 1 && dayIndex > 0) {
					this.setState({days: days, dayIndex: dayIndex - 1,}, function () {
						window.scrollTo(0, 0);
						this.initProgress();
					});
					break;
				}
				history.push(baseUrl + '/event');
				break;
			case 'accommodation':
				history.push(baseUrl + '/event-room');
				break;
			case 'price':
				history.push(baseUrl + '/accommodation');
				break;
			case 'summary':
				history.push(baseUrl + '/price');
				break;
			default:
				break;
		}
	};
	
	handleNextBtn = e => {
		e.preventDefault();
		
		const data = new FormData(e.target);
		
		const {match, location, history, generic, defCurrency} = this.props;
		const {space, countryCode, page, term, days, dayIndex, request, errors, startDate, endDate, flexibleDates, rooms, accomReq, accomStartDate, accomEndDate, sendToOthers, directContact, selectedCountry, selectedCity} = this.state;
		const currency = getCurrency(defCurrency)
		const spaceType = location.pathname.indexOf('catered-meeting') !== -1 ? 'catered-meeting' : 'catered-banquet';
		const baseUrl = (generic) ? '/request/' + spaceType + '/generic' : '/request/' + spaceType + '/' + match.params.id;
		
		if (term === 'hourly') {
			this.bookSpace(data);
			return;
		}
		
		switch (page) {
			
			case 'contact':
				
				request.fName = data.get("fname");
				request.lName = data.get("lname");
				request.email = data.get("email");
				request.phone = data.get("phone");
				request.company = data.get("company");
				
				const user = getUser();
				// if (request.phone && request.phone.length > 0) {
				// 	if (request.phone[0] === '0') {
				// 		request.phone = request.phone.substring(1);
				// 	}
				// 	const code = countryCode ? '+' + countryCode : (user && user.country) ? ('+' + getCountryCode(user.country)[3]) : '+971';
				// 	request.phone = code + request.phone;
				// }
				
				errors.fName = formValidation('fname', request.fName);
				errors.lName = formValidation('lname', request.lName);
				errors.email = formValidation('email', request.email);
				errors.phone = formValidation('phone', request.phone);
				errors.company = formValidation('company', request.company, false);
				
				Object.keys(errors).forEach(key => {
					if (!errors[key]) delete errors[key];
				});
				
				this.setState({errors: errors});
				if (Object.keys(errors).length > 0) return;
				
				
				let phone = phoneUtil.parse(request.phone)
				request.phone = phoneUtil.format(phone, PNF.E164)
				// if (window.innerWidth > 991) {
					this.sendQuote();
					return;
				// }
				// this.setState({request: request}, function () {
				// 	history.push(baseUrl + '/event');
				// });
				// return;
			
			case 'event':
				
				request.eventCity = (generic) ? data.get("event_city") : null;
				request.eventType = data.get("event_type");
				request.eventDesc = data.get("event_desc");
				request.startDate = startDate;
				request.endDate = endDate;
				request.flexibleDates = flexibleDates;
				
				errors.eventCity = (generic) ? (formValidation('', request.eventCity) === 'Field is required.' ? 'Please, select a city.' : formValidation('', request.eventCity)) : null;
				errors.eventType = formValidation('', request.eventType) === 'Field is required.' ? 'Please, choose your event type.' : formValidation('', request.eventType);
				errors.eventDesc = formValidation('', request.eventDesc) === 'Field is required.' ? 'Please, Enter the Brief explanation.' : formValidation('', request.eventType);
				errors.startDate = (!startDate || startDate === '') ? 'Please, Select Start Date.' : null;
				errors.endDate = (!endDate || endDate === '') ? 'Please, Select End Date.' : null;
				
				Object.keys(errors).forEach(key => {
					if (!errors[key]) delete errors[key];
				});
				
				this.setState({errors: errors});
				if (Object.keys(errors).length > 0) return;
				
				
				var finalDays = [];
				var newDays = [];
				
				if (startDate && endDate && startDate !== endDate) {
					const daysDiffTime = endDate.getTime() - startDate.getTime();
					const daysDiff = Math.ceil(daysDiffTime / (1000 * 3600 * 24));
					for (var x = 0; x <= daysDiff; x++) {
						newDays.push({
							selectedLayout: '',
							selectedLayoutMax: 0,
							capacity: 0,
							startTime: "",
							endTime: "",
							selectedAmenities: {},
							selectedFood: {}
						});
					}
				} else {
					newDays.push({
						selectedLayout: '',
						selectedLayoutMax: 0,
						capacity: 0,
						startTime: "",
						endTime: "",
						selectedAmenities: {},
						selectedFood: {}
					});
				}
				
				if (days.length < newDays.length) {
					for (var i = 0; i < days.length; i++) {
						newDays[i] = days[i];
					}
					finalDays = newDays;
				} else if (days.length > newDays.length) {
					finalDays = days.slice(0, days.length);
				} else {
					finalDays = days;
				}
				
				if (!generic && space && space.layouts && Object.keys(space.layouts).length === 1) {
					const key = Object.keys(space.layouts)[0];
					for (var i = 0; i < finalDays.length; i++) {
						const day = finalDays[i];
						day.selectedLayout = key;
						day.selectedLayoutMax = space.layouts[key];
					}
				}
				
				this.setState({request: request, days: finalDays, dayIndex: 0,}, function () {
					this.updateSelectedTimes(null, null, null);
					history.push(baseUrl + '/event-room');
				});
				return;
			
			case 'event-room':
				
				const day = days[dayIndex];
				
				const breakfast = day.selectedFood['breakfast'];
				const teaCoffee = day.selectedFood['tea-coffee'];
				const lunch = day.selectedFood['lunch'];
				const dinner = day.selectedFood['dinner'];
				const drinks = day.selectedFood['drinks'];
				
				if (breakfast && breakfast.selectedOption === 'Other') breakfast.optionOther = data.get("breakfast_opt_other");
				if (teaCoffee && teaCoffee.selectedOption === 'Tea & coffee with other') teaCoffee.optionOther = data.get("tea-coffee_opt_other");
				if (lunch && lunch.selectedLunch === 'Other') lunch.lunchOther = data.get("lunch_lunch_other");
				if (lunch && lunch.selectedDrink === 'Other') lunch.drinkOther = data.get("lunch_drinks_other");
				if (dinner && dinner.selectedLunch === 'Other') dinner.lunchOther = data.get("dinner_lunch_other");
				if (dinner && dinner.selectedDrink === 'Other') dinner.drinkOther = data.get("dinner_drinks_other");
				if (drinks) drinks.optionOther = data.get("drinks_other");
				
				errors.capacity = (day.capacity === '' || day.capacity === 0) ? 'Please, Enter Delegates number.' : null;
				errors.selectedLayout = (day.selectedLayout === '') ? 'Please, select layout.' : null;
				errors.startTime = (!day.startTime || day.startTime === '') ? 'Please, Select Start Time.' : null;
				errors.endTime = (!day.endTime || day.endTime === '') ? 'Please, Select End Time.' : null;
				
				errors.breakfastOption = (breakfast && breakfast.selectedOption === '') ? 'Field is required.' : null;
				errors.breakfastOther = (breakfast && breakfast.selectedOption === 'Other' && (!breakfast.optionOther || breakfast.optionOther === '')) ? 'Field is required.' : null;
				
				errors.teaCoffeeOption = (teaCoffee && teaCoffee.selectedOption === '') ? 'Field is required.' : null;
				errors.teaCoffeeOther = (teaCoffee && teaCoffee.selectedOption === 'Tea & coffee with other' && (!teaCoffee.optionOther || teaCoffee.optionOther === '')) ? 'Field is required.' : null;
				
				errors.lunchType = (lunch && lunch.selectedType === '') ? 'Field is required.' : null;
				errors.lunchLunch = (lunch && lunch.selectedLunch === '') ? 'Field is required.' : null;
				errors.lunchDrink = (lunch && lunch.selectedDrink === '') ? 'Field is required.' : null;
				errors.lunchLunchOther = (lunch && lunch.selectedLunch === 'Other' && (!lunch.lunchOther || lunch.lunchOther === '')) ? 'Field is' +
					' required.' : null;
				errors.lunchDrinkOther = (lunch && lunch.selectedDrink === 'Other' && (!lunch.drinkOther || lunch.drinkOther === '')) ? 'Field is' +
					' required.' : null;
				
				errors.dinnerType = (dinner && dinner.selectedType === '') ? 'Field is required.' : null;
				errors.dinnerLunch = (dinner && dinner.selectedLunch === '') ? 'Field is required.' : null;
				errors.dinnerDrink = (dinner && dinner.selectedDrink === '') ? 'Field is required.' : null;
				errors.dinnerLunchOther = (dinner && dinner.selectedLunch === 'Other' && (!dinner.lunchOther || dinner.lunchOther === '')) ? 'Field is' +
					' required.' : null;
				errors.dinnerDrinkOther = (dinner && dinner.selectedDrink === 'Other' && (!dinner.drinkOther || dinner.drinkOther === '')) ? 'Field is' +
					' required.' : null;
				
				errors.drinksOther = (drinks && drinks.optionOther === '') ? 'Field is required.' : null;
				
				Object.keys(errors).forEach(key => {
					if (!errors[key]) delete errors[key];
				});
				
				this.setState({errors: errors});
				if (Object.keys(errors).length > 0) return;
				
				
				request.days = days;
				
				if (days.length > 1 && dayIndex + 1 < days.length) {
					this.setState({
						request: request,
						days: days,
						dayIndex: dayIndex + 1,
						copyAlert: dayIndex + 1
					}, function () {
						window.scrollTo(0, 0);
						this.initProgress();
					});
					return;
				}
				
				history.push(baseUrl + '/accommodation');
				return;
			
			case 'accommodation':
				
				if (accomReq) {
					rooms.forEach((room, i) => {
						
						if (!(room.single_room && room.single_room > 0) && !(room.double_room && room.double_room > 0) && !(room.twin_room && room.twin_room > 0)) {
							errors["room-" + i] = 'An option is required or select "No" in the field above.'
						} else {
							delete errors["room-" + i];
						}
					});
					
					this.setState({errors: errors});
					if (Object.keys(errors).length > 0) return;
				}
				
				request.accomReq = accomReq;
				request.accomStartDate = accomStartDate;
				request.accomEndDate = accomEndDate;
				request.rooms = rooms;
				if (!request.currency) {
					request.currency = currency.name;
				}
				this.setState({request: request}, function () {
					history.push(baseUrl + '/price');
				});
				return;
			
			case 'price':
				
				request.priceType = data.get("price_type");
				request.price = data.get("price");
				request.currency = data.get("currency");
				request.sendToOthers = sendToOthers;
				request.directContact = directContact;
				
				errors.price = formValidation('', request.price);
				
				Object.keys(errors).forEach(key => {
					if (!errors[key]) delete errors[key];
				});
				
				this.setState({errors: errors});
				if (Object.keys(errors).length > 0) return;
				
				// if (window.innerWidth > 991) {
				// 	this.sendQuote();
				// 	return;
				// }
				
				this.setState({request: request}, function () {
					history.push(baseUrl + '/contact');
				});
				return;
			
			case 'summary':
				
				this.sendQuote();
				return;
			
			default:
				return;
		}
	};
	
	bookSpace = data => {
		if (!this.state.reqLoading) {
			this.setState({reqLoading: true});
			
			const {match, defCurrency} = this.props;
			const {request, countryCode, errors, space, selectedTerm, startTime, endTime, time, capacity, startDate, coupon, couponType, couponPrice, selectedCountry, selectedCity, selectedLayout, isRecurring, recurringType, recurringNum, recurring, selectedAmenities} = this.state;
			
			request.fName = data.get("fname");
			request.lName = data.get("lname");
			request.email = data.get("email");
			request.phone = data.get("phone");
			
			const user = getUser();
			// if (request.phone && request.phone.length > 0) {
			// 	if (request.phone[0] === '0') {
			// 		request.phone = request.phone.substring(1);
			// 	}
			// 	const code = countryCode ? '+' + countryCode : (user && user.country) ? ('+' + getCountryCode(user.country)[3]) : '+971';
			// 	request.phone = code + request.phone;
			// }
			
			errors.fName = formValidation('fname', request.fName);
			errors.lName = formValidation('lname', request.lName);
			errors.email = formValidation('email', request.email);
			errors.phone = formValidation('phone', request.phone);
			errors.country = formValidation('country', selectedCountry);
			errors.city = formValidation('city', selectedCity);
			
			Object.keys(errors).forEach(key => {
				if (!errors[key]) delete errors[key];
			});
			
			this.setState({errors: errors});
			if (Object.keys(errors).length > 0) {
				this.setState({reqLoading: false});
				return;
			}
			
			let phone = phoneUtil.parse(request.phone)
			request.phone = phoneUtil.format(phone, PNF.E164)
			const currency = getCurrency(defCurrency);
			const day = getDayName(startDate);
			const vat = 5;
			const transaction_fee = (space.user_transaction_fee) ? space.user_transaction_fee : 5;
			
			const start_hr = (startTime) ? parseInt(startTime.substr(0, 2)) : (time) ? parseInt(time.substr(0, 2)) : 0;
			const end_hr = (endTime) ? parseInt(endTime.substr(0, 2)) : (time) ? parseInt(time.substr(9, 2)) : 0;
			const finalRecurringNum = (recurringNum > 0) ? recurringNum : 1;
			const bookingCount = (isRecurring) ? ((recurringType === 'monthly') ? finalRecurringNum : (Object.keys(recurring).length) * finalRecurringNum) : 1;
			const timeDiff = Math.ceil(end_hr - start_hr);
			var priceAfterDisc = 0;
			var priceAfterRecurring = 0;
			var price = (selectedTerm === 'day') ? parseFloat(space.day_price) : (selectedTerm === 'half_day') ? parseFloat(space.half_day_price) : (selectedTerm === 'hourly') ? parseFloat(space.hour_price) : 0;
			
			if (space.exception_rules === 'yes') {
				if (space.exception_day1 === day) {
					price = (selectedTerm === 'day') ? parseFloat(space.exception_day_price1) : (selectedTerm === 'half_day') ? parseFloat(space.exception_half_day_price1) : (selectedTerm === 'hourly') ? parseFloat(space.exception_hour_price1) : 0;
				} else if (space.exception_day2 === day) {
					price = (selectedTerm === 'day') ? parseFloat(space.exception_day_price2) : (selectedTerm === 'half_day') ? parseFloat(space.exception_half_day_price2) : (selectedTerm === 'hourly') ? parseFloat(space.exception_hour_price2) : 0;
				}
			}
			
			price = (selectedTerm === 'hourly' && timeDiff > 0) ? timeDiff * price : price;
			const subtotal = price;
			const vatAmount = price * (vat / 100);
			price = price + vatAmount;
			const priceAfterVat = price;
			const transfeeAmount = price * (transaction_fee / 100);
			price = price + transfeeAmount;
			const priceAfterUserFee = price;
			
			priceAfterDisc = (coupon !== '' && couponType !== '' && couponPrice !== 0) ? (price - printPriceOnly(((couponType === 'percent') ? (price * (couponPrice / 100)) : couponPrice), '', '')) : price;
			
			priceAfterDisc = printPriceOnly(priceAfterDisc, space.currency, currency.name);
			
			priceAfterRecurring = (isRecurring) ? priceAfterDisc * bookingCount : priceAfterDisc;
			
			const dayTime = space.ws_avail[day.toLowerCase()];
			const gstart_hr = (dayTime === "open_24_hours") ? 0 : (parseInt(dayTime.substr(0, 2)));
			const gstart_min = (dayTime === "open_24_hours") ? 0 : (parseInt(dayTime.substr(3, 2)));
			const gend_hr = (dayTime === "open_24_hours") ? 24 : (parseInt(dayTime.substr(9, 2)));
			const gend_min = (dayTime === "open_24_hours") ? 0 : (parseInt(dayTime.substr(12, 2)));
			const halfDayTime = ("0" + gstart_hr).slice(-2) + ':' + ("0" + gstart_min).slice(-2) + ":00-" + ("0" + gend_hr).slice(-2) + ':' + ("0" + gend_min).slice(-2) + ":00";
			const fullDayTime = ("0" + gstart_hr).slice(-2) + ':' + ("0" + gstart_min).slice(-2) + ":00-" + ("0" + gend_hr).slice(-2) + ':' + ("0" + gend_min).slice(-2) + ":00";
			
			let amenities = {}
			
			CATERED_AMENITIES.forEach(
				(amenity) => {
					amenities[`chk_${amenity.key}`] = Boolean(selectedAmenities?.[amenity.id])
				}
			)
			
			const url = getApiLinkV2('workspace/booking');
			const token = getToken();
			
			const reqData = {
				submit_new: true,
				amenities,
				workspace_id: match.params.id,
				workspace_type_id: space.workspace_type_id,
				first_name: request.fName,
				last_name: request.lName,
				email: request.email,
				phone_code: '+971',
				phone: request.phone,
				country: selectedCountry,
				city: selectedCity,
				reservation_type: selectedTerm,
				currency: currency.name,
				final_currency: (currency.name == 'USD' || currency.name == 'AED' ? currency.name : 'USD'),
				final_payment: ((currency.name == 'USD' || currency.name == 'AED') ? priceAfterRecurring : printPriceOnly(priceAfterRecurring, currency.name, 'USD')),
				tax_name: 'VAT',
				min_booking_hrs: ((this.state.term === 'hourly' && space.min_booking_hrs) ? parseInt(space.min_booking_hrs) : 1),
				recurring: (isRecurring ? 'on' : 'off'),
				day: Object.keys(recurring),
				monthly_type: (recurring.monthly && recurring.monthly === 1) ? "day" : "date",
				monthly_date: "",
				recurring_count: recurringNum,
				duration: timeDiff,
				recurringType: recurringType,
				subtotal: subtotal,
				sales_tax: vat,
				sales_tax_amt: vatAmount,
				amt_due_after_tax: priceAfterVat,
				user_transaction_fee_amt: transfeeAmount,
				amt_due_after_fee: priceAfterUserFee,
				discount_type: couponType,
				discount: couponPrice,
				discount_amt: (coupon !== '' && couponType !== '' && couponPrice !== 0) ? ((couponType === 'percent') ? (price * (couponPrice / 100)) : (price - couponPrice)) : 0,
				amt_due_after_disc: priceAfterDisc,
				sales_price: priceAfterDisc,
				total_price: priceAfterDisc,
				total_recurring_amt: priceAfterRecurring,
				amount_due_rec: priceAfterRecurring,
				total_price_rec: priceAfterRecurring,
				booking_date: printDateForServer(startDate),
				start_time: startTime,
				end_time: endTime,
				capacity: capacity,
				coupon_code: coupon,
				half_day_time: ((selectedTerm == 'half_day') ? document.getElementById("time").value : halfDayTime),
				day_time: ((selectedTerm == 'day') ? document.getElementById("time").value : fullDayTime),
				layout: selectedLayout,
				duration_note_txt: "",
				duration_note_txt_usd: "",
				title: "",
				hour_price: space.hour_price,
				half_day_price: space.half_day_price,
				day_price: space.day_price,
				exception_rules: space.exception_rules,
				immediate_capture: false,
				redirect_url: new URL(
					PaymentVerification.generatePath(
						{
							booking_type: "st",
							workspace_id: match.params.id,
							workspace_type: getTypeTextKey(space.workspace_type_id),
							event: 'verify',
						}
					),
					window.location.origin
				).toString(),
				space_type: space.workspace_type_id,
			}
			
			devLog(reqData.redirect_url);
			devLog(reqData, {recurring, keys: Object.keys(recurring)});
			
			getAxios().post(url, reqData, {
				headers: {
					'Cache-Control': 'no-cache',
					'Content-Type': 'application/json',
					'Authorization': 'Bearer ' + token,
				},
			}).then(response => {
				if (response.data.payment_url) {
					space.order_id = response.data.booking_reference;
					space.payment_id = response.data.p_id;
					resetBookings();
					setSpaceBooking(space);
					this.setState({reqLoading: false});
					window.removeEventListener('beforeunload', this.handleUnLoad);
					window.open(response.data.payment_url, '_top');
					return;
				}
				if (response.data) {
					devLog(response.data);
					// devLog(response.data.payment_url);
				}
			}).catch(error => {
				Bugsnag.notify(error);
				if (error.response.data) {
					devLog(error.response.data);
				}
				createAlert(this, 'danger', (error.response && error.response.data.message) ? 'Error: ' : '', (error.response && error.response.data.message) ? error.response.data.message : error.message);
				this.setState({reqLoading: false});
			});
		}
	};
	
	sendQuote = () => {
		if (!this.state.reqLoading) {
			
			this.setState({reqLoading: true});
			
			const {match, location, history, generic} = this.props;
			const {request} = this.state;
			let {persons} = this.state;
			
			request.days.forEach((day, idx) => {
				
				day.participants = day.capacity;
				day.start_time = day.startTime;
				day.end_time = day.endTime;
				day.rdo_layout = day.selectedLayout;
				persons += day.capacity;
				
				CATERED_AMENITIES.forEach(
					({label, key}) => {
						let qty = day.selectedAmenities[label]
						day[`chk_${key}`] = Boolean(qty);
						day[`${key}_qty`] = qty;
					}
				)
				
				
				devLog(`Day-${idx}`, day.selectedAmenities)
				const breakfast = day.selectedFood['breakfast'];
				const teaCoffee = day.selectedFood['tea-coffee'];
				const lunch = day.selectedFood['lunch'];
				const dinner = day.selectedFood['dinner'];
				const drinks = day.selectedFood['drinks'];
				
				if (breakfast) {
					day.chk_breakfast = true;
					day.breakfast_qty = day.capacity;
					day.rdo_breakfast = breakfast.selectedOption;
					if (breakfast.optionOther) {
						day.rdo_breakfast_other = breakfast.optionOther;
					}
				}
				if (teaCoffee) {
					day.chk_tea_coffee = true;
					day.tea_coffee_qty = day.capacity;
					day.rdo_tea_coffee = teaCoffee.selectedOption;
					if (teaCoffee.optionOther) {
						day.rdo_tea_coffee_other = teaCoffee.optionOther;
					}
				}
				if (lunch) {
					day.chk_lunch = true;
					day.lunch_qty = day.capacity;
					day.rdo_lunch = lunch.selectedLunch;
					day.rdo_lunch_drinks = lunch.selectedDrink;
					if (lunch.lunchOther) {
						day.rdo_lunch_other = lunch.lunchOther;
					}
					if (lunch.drinkOther) {
						day.rdo_lunch_drinks_other = lunch.drinkOther;
					}
				}
				if (dinner) {
					day.chk_dinner = true;
					day.dinner_qty = day.capacity;
					day.rdo_dinner = dinner.selectedLunch;
					day.rdo_dinner_drinks = dinner.selectedDrink;
					if (dinner.lunchOther) {
						day.rdo_dinner_other = dinner.lunchOther;
					}
					if (dinner.drinkOther) {
						day.rdo_dinner_drinks_other = dinner.drinkOther;
					}
				}
				if (drinks) {
					day.chk_drinks = true;
					day.drinks_qty = day.capacity;
					day.rdo_drinks_other = drinks.optionOther;
				}
			});
			
			const url = getApiLinkV2('workspace/request-quote');
			const token = getToken();
			const headers = {'Content-Type': 'application/json'};
			if (token) {
				headers['Authorization'] = 'Bearer ' + token;
			}
			
			
			// const daysCount = Math.ceil((request.endDate.getTime() - request.startDate.getTime()) / (1000 * 3600 * 24));
			// const accomDaysCount = (request.accomReq) ? Math.ceil(((request.accomEndDate.getTime() - request.accomStartDate.getTime()) / (1000 * 3600 * 24)) + 1) : 0;
			const daysCount = request.days.length ?? 1;
			const accomDaysCount = (request.accomReq) ? request.rooms.length : 0;
			const source = getSource();
			
			const data = {
				workspace_id: (generic) ? 0 : match.params.id,
				first_name: request.fName,
				last_name: request.lName,
				email: request.email,
				address: '',
				lng: 0,
				lat: 0,
				phone_code: '+20',
				phone: request.phone,
				company: request.company,
				city: request.eventCity,
				country: this.state.selectedCountry,
				source: source,
				event_type: request.eventType,
				event_description: request.eventDesc,
				venue_types: request.selectedVenues,
				start_date: printDateForServer(request.startDate),
				end_date: printDateForServer(request.endDate),
				chk_flexible_date: (request.flexibleDates ? 1 : 0),
				day: request.days,
				hdn_days: ((daysCount > 0) ? daysCount : 1),
				chk_bedroom: (request.accomReq ? 1 : 0),
				acc_day: request.rooms,
				acc_days: accomDaysCount,
				acc_from_date: printDateForServer(request.accomStartDate),
				acc_to_date: printDateForServer(request.accomEndDate),
				budget: request.price,
				budget_type: request.priceType,
				budget_curr: request.currency,
				chk_forward: (request.sendToOthers ? 1 : 0),
				chk_direct_contact: (request.directContact ? 1 : 0),
			}
			devLog(data)
			getAxios().post(url, data, {
				headers: headers,
			}).then(response => {
				this.setState({reqLoading: false}, function () {

					const sendToSimilarRequestsData = {
						...data,
						similar_requests: true,
						quote_id: response.data.quote_id
					} 

					getAxios().post(url, sendToSimilarRequestsData, {
						headers: headers,
					});
					
					const spaceType = location.pathname.indexOf('catered-banquet') !== -1 ? 'Event Space' : 'Meeting Room';
					var eventLabel = spaceType + ' Catered Success from ' + source;
					if (!generic) eventLabel = eventLabel + ' for space ' + match.params.id;
					ReactGA.event({
						category: 'DDR',
						action: 'DDR_Success' + (generic ? '_Generic' : '_Unique'),
						label: eventLabel
					});
					
					const page = location.pathname.indexOf('catered-meeting') !== -1 ? 'catered-meeting' : 'catered-banquet';
					var baseUrl = (generic) ? '/request/' + page + '/generic' : '/request/' + page + '/' + match.params.id;
					baseUrl = (source) ? baseUrl + '/success?source=' + source : baseUrl + '/success';
					const user = getUser();
					let FirstNameUser;
					let LastNameUser;
					let budget_price;
					let PhoneNumberUser;
					let emailAddressUser;
					let event_category;
					if(request.eventType === 'Company/ Business meeting' || request.eventType === 'Company Team Building' ||
					request.eventType === 'Company/ Business Lunch' || request.eventType === 'Company/ Business Dinner' || 
					request.eventType === 'Training Session(s)' || request.eventType === 'Conference' || request.eventType === 'Product(s) Launch'){
						event_category = 'Corporate';
					}

					if(request.eventType === 'Anniversary' || request.eventType === 'Wedding' ||
					request.eventType === 'Birthday' || request.eventType === 'Baby Shower' || 
					request.eventType === 'Family/ Friends Lunch' || request.eventType === 'Family/ Friends Dinner'){
						event_category = 'Social';
					}

					if(request.eventType === 'Other events'){
						event_category = 'Other';
					}
					if(request.currency !== 'AED'){
						budget_price = printPriceWithoutCurrencyName(request.price, request.currency, 'AED');
					}else{
						budget_price = request.price;
					}
					if(request.priceType === 'person'){
						if(request.currency !== 'AED'){
							budget_price = printPriceWithoutCurrencyName(persons * request.price, request.currency, 'AED');
						}else{
						    budget_price = persons * request.price;
						}
					}
					if(!user){
						FirstNameUser = request.fName;
						LastNameUser = request.lName;
						PhoneNumberUser = request.phone;
						emailAddressUser = request.email;
					}else{
					  FirstNameUser = user?.first_name;
					  LastNameUser = user?.last_name;
					  PhoneNumberUser = user?.phone;
					  emailAddressUser =  user?.email;
					}
					if(window.location.href.includes('catered-banquet')){
						window.dataLayer = window.dataLayer || [];
						window.dataLayer.push({
							event: "Banquet_Halls_DDR_Submission",
							'First Name': FirstNameUser,
							'Last Name' :LastNameUser,
							'Budget':Math.round(budget_price),
							'Email': emailAddressUser,
							'Phone':PhoneNumberUser,
							'Event Type':request.eventType,
							'Event Category':event_category
						});
					}

					if(window.location.href.includes('catered-meeting')){
						window.dataLayer = window.dataLayer || [];
						window.dataLayer.push({
							event: "Meeting_Rooms_DDR_Submission",
							'First Name': FirstNameUser,
							'Last Name' :LastNameUser,
							'Budget':Math.round(budget_price),
							'Email': emailAddressUser,
							'Phone':PhoneNumberUser,
							'Event Type':request.eventType,
							'Event Category':event_category
						});
					}
					history.push(baseUrl);
				});
			}).catch(error => {
				devLog({error})
				Bugsnag.notify(error);
				createAlert(this, 'danger', (error.response && error.response.data.message) ? 'Error: ' : '', (error.response && error.response.data.message) ? error.response.data.message : error.message);
				this.setState({reqLoading: false});
			});
		}
	};
	
	initProgress = () => {
		
		const {page, days, dayIndex} = this.state;
		
		switch (page) {
			case 'contact':
				this.setState({progress: 1});
				break;
			case 'event':
				this.setState({progress: 20});
				break;
			case 'event-room':
				this.setState({progress: 10 + ((30 / days.length) * (dayIndex + 1))});
				break;
			case 'accommodation':
				this.setState({progress: 70});
				break;
			case 'price':
				this.setState({progress: 80});
				break;
			case 'summary':
				this.setState({progress: 90});
				break;
			case 'success':
				this.setState({progress: 100});
				break;
			default:
				break
		}
	};
	
	initPlans = () => {
		
		const {space} = this.state;
		var selectedTerm = 'hourly';
		selectedTerm = (space.day_pass && space.day_price > 0) ? 'day' : '';
		selectedTerm = (space.half_day_pass && space.half_day_price > 0) ? 'half_day' : selectedTerm;
		selectedTerm = (space.hour_pass && space.hour_price > 0) ? 'hourly' : selectedTerm;
		this.setState({selectedTerm: selectedTerm});
	};
	
	initDate = () => {
		
		const {generic} = this.props;
		const {space} = this.state;
		
		if (generic) {
			const today = new Date();
			const tomorrow = addDays(today, 1);
			this.setState({startDate: today, endDate: today, accomStartDate: today, accomEndDate: tomorrow,});
			// this.updateSelectedTimes(today, null, null);
			return;
		}
		
		var excluded = {}
		Object.keys(space.ws_avail).forEach(day => {
			const val = space.ws_avail[day];
			if (val === "closed") {
				if (day === 'sunday') {
					excluded[0] = true;
				} else if (day === 'monday') {
					excluded[1] = true;
				} else if (day === 'tuesday') {
					excluded[2] = true;
				} else if (day === 'wednesday') {
					excluded[3] = true;
				} else if (day === 'thursday') {
					excluded[4] = true;
				} else if (day === 'friday') {
					excluded[5] = true;
				} else if (day === 'saturday') {
					excluded[6] = true;
				}
			}
		});
		
		const nextAvlDate = new Date();
		const date = this.getNextAvlDay(excluded, nextAvlDate, 0);
		const nextDate = addDays(date, 1);
		
		this.setState({
			startDate: null,
			endDate: null,
			accomStartDate: date,
			accomEndDate: nextDate,
			excludedDays: excluded,
		});
		this.updateSelectedTimes(date, null, null);
	};
	
	getNextAvlDay = (excluded, date, counter) => {
		
		var dayIndex = date.getDay();
		if (dayIndex > 6) {
			dayIndex = dayIndex - 6;
		}
		if (counter > 6) {
			counter = counter - 6;
		}
		
		const formattedDate = date.toLocaleDateString("en-US", {year: 'numeric', month: 'short', day: 'numeric'});
		
		if (excluded[dayIndex] || excludedDates[formattedDate]) {
			const nextDate = addDays(date, 1);
			return this.getNextAvlDay(excluded, nextDate, counter + 1);
		} else {
			var newDate = new Date();
			newDate.setDate(new Date().getDate() + counter);
			return newDate;
		}
	};
	
	updateDates = dates => {
		
		const {excludedDays} = this.state;
		const [start, end] = dates;
		
		if (!start || !end) {
			this.setState({startDate: start, endDate: end,});
			return;
		}
		
		const daysDiffTime = end.getTime() - start.getTime();
		const daysDiff = Math.ceil(daysDiffTime / (1000 * 3600 * 24));
		
		if (daysDiff > 0) {
			const startDay = start.getDay();
			var counter = 0;
			for (var x = startDay; x <= startDay + daysDiff; x++) {
				var finalX = (x > 6) ? x - 7 : x;
				var date = (x === startDay) ? start : addDays(start, counter);
				counter++;
				const formattedDate = date.toLocaleDateString("en-US", {
					year: 'numeric',
					month: 'short',
					day: 'numeric'
				});
				if (excludedDays[finalX] || excludedDates[formattedDate]) {
					this.setState({startDate: end, endDate: end,});
					return
				}
			}
		}
		
		this.setState({startDate: start, endDate: end,});
	};
	
	updateEndDate = endDate => {
		
		const {startDate, excludedDays} = this.state;
		
		if (!startDate || !endDate) {
			this.setState({endDate: startDate, rooms: [],});
			return;
		}
		
		const daysDiffTime = endDate.getTime() - startDate.getTime();
		const daysDiff = Math.ceil(daysDiffTime / (1000 * 3600 * 24));
		
		if (daysDiff > 0) {
			const startDay = startDate.getDay();
			var counter = 0;
			for (var x = startDay; x <= startDay + daysDiff; x++) {
				var finalX = (x > 6) ? x - 7 : x;
				var date = (x === startDay) ? startDate : addDays(startDate, counter);
				counter++;
				const formattedDate = date.toLocaleDateString("en-US", {
					year: 'numeric',
					month: 'short',
					day: 'numeric'
				});
				devLog(excludedDates[formattedDate]);
				if (excludedDays[finalX] || excludedDates[formattedDate]) {
					this.setState({endDate: startDate, rooms: [],});
					return
				}
			}
		}
		
		this.setState({endDate: endDate,});
	};
	
	updateAccomDates = dates => {
		
		const {excludedDays} = this.state;
		const [start, end] = dates;
		
		if (!start || !end) {
			this.setState({accomStartDate: start, accomEndDate: end, rooms: [],});
			return;
		}
		
		var rooms = [];
		const daysDiffTime = end.getTime() - start.getTime();
		const daysDiff = Math.ceil(daysDiffTime / (1000 * 3600 * 24));
		
		if (daysDiff > 0) {
			
			if (Object.keys(excludedDays).length > 0) {
				const startDay = start.getDay();
				for (var x = startDay; x <= startDay + daysDiff; x++) {
					var finalX = (x > 6) ? x - 7 : x
					if (excludedDays[finalX]) {
						this.setState({accomStartDate: end, accomEndDate: end, rooms: [],});
						return
					}
				}
			}
			for (var i = 0; i < daysDiff; i++) {
				rooms.push({});
			}
		}
		
		this.setState({accomStartDate: start, accomEndDate: end, rooms: rooms,});
	};
	
	updateAccomEndDate = accomEndDate => {
		
		const {accomStartDate, excludedDays} = this.state;
		
		if (!accomStartDate || !accomEndDate) {
			this.setState({accomEndDate: accomStartDate, rooms: [],});
			return;
		}
		
		var rooms = [];
		const daysDiffTime = accomEndDate.getTime() - accomStartDate.getTime();
		const daysDiff = Math.ceil(daysDiffTime / (1000 * 3600 * 24));
		
		if (daysDiff > 0) {
			
			if (Object.keys(excludedDays).length > 0) {
				const startDay = accomStartDate.getDay();
				for (var x = startDay; x <= startDay + daysDiff; x++) {
					var finalX = (x > 6) ? x - 7 : x
					if (excludedDays[finalX]) {
						this.setState({accomEndDate: accomStartDate, rooms: [],});
						return
					}
				}
			}
			for (var i = 0; i < daysDiff; i++) {
				rooms.push({});
			}
		}
		
		this.setState({accomEndDate: accomEndDate, rooms: rooms,});
	};
	
	updateDate = date => {
		
		this.setState({startDate: date});
	};
	
	handleSelectPlan = term => {
		
		this.updateSelectedTimes(null, term, null);
		
		this.setState({selectedTerm: term});
	};
	
	onStartTimeChange = () => {
		
		const startTime = document.getElementById("start_time").value;
		this.setState({startTime: startTime});
		this.updateSelectedTimes(null, null, startTime);
	};
	
	onEndTimeChange = () => {
		
		this.setState({endTime: document.getElementById("end_time").value});
	};
	
	onTimeChange = () => {
		
		this.setState({time: document.getElementById("time").value});
	};
	
	updateSelectedTimes = (date = null, term = null, start = null) => {
		
		const {generic} = this.props;
		const {space, days, dayIndex, selectedTerm, startDate, startTime,} = this.state;
		
		var finalDate = (date) ? date : startDate;
		var finalTerm = (term) ? term : selectedTerm;
		var finalStartTime = (start) ? start : startTime;
		if (!finalDate) return;
		var dayIdx = finalDate.getDay();
		
		var day = '';
		if (dayIdx === 0) {
			day = 'sunday';
		} else if (dayIdx === 1) {
			day = 'monday';
		} else if (dayIdx === 2) {
			day = 'tuesday';
		} else if (dayIdx === 3) {
			day = 'wednesday';
		} else if (dayIdx === 4) {
			day = 'thursday';
		} else if (dayIdx === 5) {
			day = 'friday';
		} else if (dayIdx === 6) {
			day = 'saturday';
		}
		
		var fStartTime = "";
		var fEndTime = "";
		var fTime = "";
		
		if (generic) {
			const day = days[dayIndex];
			const start_hr = (finalStartTime) ? parseInt(finalStartTime.substr(0, 2)) : 7;
			const start_min = (finalStartTime) ? parseInt(finalStartTime.substr(3, 2)) : 0;
			day.startTime = ("0" + start_hr).slice(-2) + ':' + ("0" + start_min).slice(-2) + ":00"
			day.endTime = ("0" + (start_hr + 1)).slice(-2) + ':' + ("0" + start_min).slice(-2) + ":00"
			this.setState({days: days});
			return;
		}
		
		if (!space.ws_avail || (space.ws_avail[day] == null) || space.ws_avail[day] === "closed") {
			this.setState({startTime: "00:00:00", endTime: "00:00:00", time: "00:00:00"});
			return;
		}
		
		const dayTime = space.ws_avail[day];
		
		const minHoursDiff = (this.state.term === 'hourly' && space.min_booking_hrs) ? parseInt(space.min_booking_hrs) : 1;
		
		if (this.state.term === 'catered' || finalTerm === 'hourly') {
			
			const start_hr = (finalStartTime) ? parseInt(finalStartTime.substr(0, 2)) : ((dayTime === "open_24_hours") ? 0 : (parseInt(dayTime.substr(0, 2))));
			const start_min = (finalStartTime) ? parseInt(finalStartTime.substr(3, 2)) : ((dayTime === "open_24_hours") ? 0 : parseInt(dayTime.substr(3, 2)));
			fStartTime = ("0" + start_hr).slice(-2) + ':' + ("0" + start_min).slice(-2) + ":00"
			fEndTime = ("0" + (start_hr + minHoursDiff)).slice(-2) + ':' + ("0" + start_min).slice(-2) + ":00"
			
			if (this.state.term === 'catered' && days.length > 0) {
				const day = days[dayIndex];
				day.startTime = fStartTime;
				day.endTime = fEndTime;
				this.setState({days: days});
			} else {
				this.setState({startTime: fStartTime, endTime: fEndTime});
			}
			
		} else if (finalTerm === 'half_day') {
			
			const start_hr = (dayTime === "open_24_hours") ? 0 : (parseInt(dayTime.substr(0, 2)));
			const start_min = (dayTime === "open_24_hours") ? 0 : parseInt(dayTime.substr(3, 2));
			fTime = ("0" + start_hr).slice(-2) + ':' + ("0" + start_min).slice(-2) + ":00-" + ("0" + start_hr + 4).slice(-2) + ':' + ("0" + start_min).slice(-2) + ":00"
			
			this.setState({time: fTime});
			
		} else if (finalTerm === 'day') {
			
			const start_hr = (dayTime === "open_24_hours") ? 0 : (parseInt(dayTime.substr(0, 2)));
			const start_min = (dayTime === "open_24_hours") ? 0 : parseInt(dayTime.substr(3, 2));
			const end_hr = (dayTime === "open_24_hours") ? 24 : parseInt(dayTime.substr(9, 2));
			const end_min = (dayTime === "open_24_hours") ? 0 : parseInt(dayTime.substr(12, 2));
			fTime = ("0" + start_hr).slice(-2) + ':' + ("0" + start_min).slice(-2) + ":00-" + ("0" + end_hr).slice(-2) + ':' + ("0" + end_min).slice(-2) + ":00"
			
			this.setState({time: fTime});
		}
	};
	
	checkCouponCode = () => {
		
		this.setState({couponLoading: true});
		
		const code = document.getElementById("coupon").value;
		if (!code || code === '') {
			this.setState({coupon: '', couponType: '', couponPrice: 0, couponLoading: false});
			return
		}
		
		const url = getApiLinkV2('workspace/coupon/' + code);
		const token = getToken();
		const headers = {'Content-Type': 'application/json'};
		if (token) {
			headers['Authorization'] = 'Bearer ' + token;
		}
		getAxios().get(url, {
			headers: headers,
		}).then(response => {
			if (response.data.status && response.data.status === 'active') {
				createAlert(this, 'success', '', 'Coupon has been added successfully.');
				this.setState({
					coupon: code,
					couponType: response.data.coupon_type,
					couponPrice: response.data.coupon_price,
					couponLoading: false
				});
				return
			}
			createAlert(this, 'danger', '', (response.data.status && response.data.status === 'expired') ? 'Expired Coupon' : 'Invalid coupon code');
			this.setState({coupon: '', couponType: '', couponPrice: 0, couponLoading: false});
		}).catch(error => {
			Bugsnag.notify(error);
			createAlert(this, 'danger', (error.response && error.response.data.message) ? 'Error: ' : '', (error.response && error.response.data.message) ? error.response.data.message : error.message);
			this.setState({coupon: '', couponType: '', couponPrice: 0, couponLoading: false});
		});
	};
	
	render() {
		
		const {match, location, generic, defCurrency} = this.props;
		const {page, space, countryCode, term, reqAlert, reqLoading, selectedCountry, selectedCity, couponLoading, showCoupon, coupon, couponType, couponPrice, selectedTerm, selectedLayout, selectedAmenities, progress, time, startDate, endDate, accomStartDate, accomEndDate, startTime, endTime, excludedDays, capacity, flexibleDates, isRecurring, recurring, recurringType, recurringNum, accomReq, rooms, days, dayIndex, sendToOthers, directContact, copyAlert, request, errors} = this.state;
		
		const currency = getCurrency(defCurrency);
		const currencyList = getCurrencies();
		const user = getUser();

		if (term === 'hourly' && !user) {
			return (<Redirect to={{pathname: '/login', state: {prevLocation: location}}}/>);
		}
		if (!space && !generic) {
			return (<Redirect to={'/space/' + match.params.id + '?t=' + term}/>);
		}
		if (space && Object.keys(space).length === 0 && !generic) {
			return (<></>);
		}
		if( (page === "event-room" && !request.eventCity) || (page === "accommodation" && !request.eventCity)
		
		|| (page === "price" && !request.eventCity) || (page === "contact" && !request.eventCity)
		){
			return (<Redirect to={'/'}/>);
		}
		return (
			<>
				<Helmet>
					
					{/* Event snippet for Generic LT Success (2024) conversion page */}
					<script>
					{`
						window.gtag = window.gtag || function() { window.dataLayer.push(arguments); };
						gtag('js', new Date());
						gtag('config', 'AW-935885959');
						window.gtag('event', 'conversion', {"send_to": "AW-935885959/0gQcCNuT6bIZEIf5ob4D"});
						window.gtag('event', 'conversion', {"send_to": "AW-935885959/2gKpCKnx9_UCEIf5ob4D"});
						window.gtag('event', 'conversion', {"send_to": "AW-935885959/3KwICPG5z_UCEIf5ob4D"});
						window.gtag('event', 'conversion', {"send_to": "AW-935885959/WLCvCO_p0fUCEIf5ob4D"});
					`}
					</script>

				</Helmet>
				<NavBar backLink={(generic) ? null : '/space/' + match.params.id + '?t=' + term} smallLogo={true}
				        smallNav={true} hideSearch={true} hideHostBtn={true} progress={progress}
				        pageTitle={(generic) ? 'Get a Quote' : 'Request to book space'} />
				
				<div id="slide-cont"
				     className={"container-fluid slide-cont sticky " + (term === 'catered' ? "top-42" : "") + (generic ? "hide-mobile" : "")}>
					<div className="container-lg">
						<div className="booking-page pt-1 ">
							<div className="space-container">
								<div className="space-content"/>
								<div id="space-stick" className="space-stick"
								     style={(generic && (page === 'event' || page === 'success')) ? {display: 'none'} : {}}>
									
									{(space && !generic && (
										<div className="book-toprow">
											<div style={{height: 80}}>
												<div className="space-div" style={{flexDirection: "row"}}>
													<img className="space-img"
													     src={"https://fluidmeet.s3.amazonaws.com/venue_logos/" + space.image}/>
													
													{/*<div style={{position:'absolute',top: 0,left: 10,right: 10,height: 'calc(100% - 140px)'}} />*/}
													
													<div className="space-details pt-2" style={{height: 'auto'}}>
														<div className="space-rate">
															<FontAwesomeIcon icon={faStar}
															                 style={{color: (space.workspace_rating && space.workspace_rating > 0) ? '#00a9e0' : '#E0E0E0'}}/>{" "}
															{(space.workspace_rating && space.workspace_rating > 0) ? (
																space.workspace_rating
															) : (
																// (space.date_added) ? (
																//   isSpaceOld(space.date_added)
																// ) : (
																""
																// )
															)}
															{(space.reviews > 0) ? (
																<span className="full">({space.reviews})</span>
															) : (
																<></>
															)}
														</div>
														<span className="space-location">{space.front_address}</span>
														<span className="space-title">{space.name}</span>
													</div>
												</div>
											</div>
										</div>
									))}
									
									{(term === 'hourly') ? (
										<div className="space-about booking-p">
											<h3 className="pb-1">Price details</h3>
											{this.printPriceDetails(space, coupon, couponType, couponPrice, selectedTerm, startDate, startTime, endTime, isRecurring, recurring, recurringType, recurringNum, currency)}
										</div>
									) : (term === 'catered' && page !== 'event' && page !== 'success') ? (
										<div id={(space) ? "" : "space-summ"}
										     className={"space-about booking-p " + ((space) ? '' : 'border-0')}>
											<h3 className="pb-1">Request Summary</h3>
											{this.printRequestSummary(space, page, request)}
										</div>
									) : (
										<></>
									)}
								
								</div>
							</div>
						</div>
					</div>
				</div>
				
				<div className="container-fluid bg-white h-z">
					<div className="container-lg h-z pl-mobile-0 pr-mobile-0">
						<div className={"booking-page ps-187 h-z " + ((generic) ? "pt-80" : "")}>
							
							<h1 className="full-title h-z">
								{(!generic && (
									<FontAwesomeIcon icon={faArrowLeft}
									                 onClick={this.handleExitBooking}
									                 style={{
										                 color: '#000000',
										                 fontSize: 20,
										                 cursor: 'pointer',
										                 marginRight: 24,
										                 marginBottom: 4
									                 }}/>
								))}
								{(generic) ? 'Get a Quote' : 'Request to book space'}
							</h1>
							
							<div className="space-container">
								
								<div className="space-content h-z">
									
									<div className="form-general p-0">
										<form className="mt-4 form-flex" autoComplete="on"
										      onSubmit={this.handleNextBtn}>
											
											{(term === 'hourly' && (
												<>
													<div className="space-about pt-3 pb-0 border-0">
														<h3>Plan</h3>
														<div className="row pr-2 pl-2 mb-3">
															
															{(space.hour_pass && space.hour_price > 0 && (
																<div className="col-md-4 col-12 pr-2 pl-2">
																	<div
																		className={"space-options " + ((selectedTerm === 'hourly') ? 'options-selected' : '')}
																		onClick={() => this.handleSelectPlan('hourly')}>
																		<div className="bold">Hour</div>
																		<div className="pt-1">
																			{printPrice(space.hour_price, space.currency, currency.name, true)}
																		</div>
																	</div>
																</div>
															))}
															
															{(space.half_day_pass && space.half_day_price > 0 && (
																<div className="col-md-4 col-12 pr-2 pl-2">
																	<div
																		className={"space-options " + ((selectedTerm === 'half_day') ? 'options-selected' : '')}
																		onClick={() => this.handleSelectPlan('half_day')}>
																		<div className="bold">Half day</div>
																		<div className="pt-1">
																			{printPrice(space.half_day_price, space.currency, currency.name, true)}
																		</div>
																	</div>
																</div>
															))}
															
															{(space.day_pass && space.day_price > 0 && (
																<div className="col-md-4 col-12 pl-2 pr-2">
																	<div
																		className={"space-options " + ((selectedTerm === 'day') ? 'options-selected' : '')}
																		onClick={() => this.handleSelectPlan('day')}>
																		<div className="bold">Day</div>
																		<div className="pt-1">
																			{printPrice(space.day_price, space.currency, currency.name, true)}
																		</div>
																	</div>
																</div>
															))}
														
														</div>
													</div>
													
													<div className="space-about pt-4 pb-2 border-0">
														<h3>Booking info</h3>
														<div className="row mb-2">
															
															<div className="col-12 pr-3 pl-3 pt-4">
																<h5 className="pb-2">Date</h5>
																<div className="selector date-picker">
																	<FontAwesomeIcon icon={faCalendar}
																	                 style={{
																		                 width: 24,
																		                 height: 24,
																		                 color: '#989898',
																		                 position: 'absolute',
																		                 top: 15,
																		                 right: 15,
																	                 }}/>
																	<div
																		className="dates-picked">{(startDate) ? startDate.toLocaleDateString("en-US", dateOptions) : ''}</div>
																	<DatePicker
																		onFocus={(e) => e.target.readOnly = true}
																		onChangeRaw={this.handleDateChangeRaw}
																		excludeDates={Object.keys(excludedDates).map(date => new Date(date))}
																		filterDate={(date) => excludedDays[date.getDay()] == null}
																		selected={startDate}
																		minDate={new Date()}
																		onChange={this.updateDate}
																		disabledNavigation/>
																</div>
															</div>
															
															<div
																className={"col-6 pt-2 pr-mobile-1 " + ((selectedTerm === 'hourly') ? "" : "hide")}>
																<h5 className="pb-2">Start time</h5>
																<div className="selector mb-0">
																	<select name="start_time" id="start_time"
																	        className="select" value={startTime}
																	        onChange={this.onStartTimeChange}>
																		{this.printTimeOptions(space, selectedTerm, startDate, 'start', startTime)}
																	</select>
																</div>
															</div>
															<div
																className={"col-6 pt-2 pl-mobile-1 " + ((selectedTerm === 'hourly') ? "" : "hide")}>
																<h5 className="pb-2">End time</h5>
																<div className="selector mb-0">
																	<select name="end_time" id="end_time"
																	        className="select" value={endTime}
																	        onChange={this.onEndTimeChange}>
																		{this.printTimeOptions(space, selectedTerm, startDate, 'end', startTime)}
																	</select>
																</div>
															</div>
															
															<div
																className={"col-12 pt-2 " + ((selectedTerm === 'hourly') ? "hide" : "")}>
																<h5 className="pb-2">Time</h5>
																<div className="selector">
																	<select name="time" id="time" className="select"
																	        value={time}
																	        onChange={this.onTimeChange}>
																		{this.printTimeOptions(space, selectedTerm, startDate)}
																	</select>
																</div>
															</div>
															
															{(selectedTerm === 'hourly' && parseInt(space.min_booking_hrs) > 1 && (
																<div className="mt-2 ml-3 mr-3 mb-3 w100">Min
																	booking: {(space.min_booking_hrs) ? space.min_booking_hrs : '1'}{(space.min_booking_hrs > 1) ? ' hours ' : ' hour '}min</div>
															))}
															
															{(space.layouts && Object.keys(space.layouts).length > 0 && (
																<div className="col-12 pl-3 pr-3 pt-4 pb-3 border-0">
																	<h5>Select layout</h5>
																	<div className="row pl-2 pr-2">
																		{Object.keys(space.layouts).map(key => (
																			<div key={key} className="layout-col">
																				<div
																					className={"space-layout " + ((selectedLayout === key) ? 'options-selected' : '')}
																					onClick={() => this.setState({
																						selectedLayout: key,
																						selectedLayoutMax: space.layouts[key]
																					})}>
																					<img src={getLayoutImg(key)}/>
																					<div
																						className="layout-count">{space.layouts[key]}</div>
																				</div>
																			</div>
																		))}
																	</div>
																</div>
															))}
															
															<div className="col-12 more-counter mb-2 border-0">
																<h5 className="capacity-title">Capacity</h5>
																<span id="count" className="counter">
                                    <input type="number" className="form-control" placeholder="1" value={capacity}
                                           onChange={this.handleUpdateCapacity}
										   onWheel={ event => event.currentTarget.blur() }
										   />
                                  </span>
															</div>
															
															<div
																className="col-12 mt-3 mb-2 display-flex more-dates border-0">
																<div className="title-col">
																	<h5 className="mt-2">Recurring</h5>
																</div>
																<div className="on-off-col">
                                    <span className="on-off">
                                      <span className={(isRecurring) ? "on-active" : ""}
                                            onClick={() => this.toggleRecurring(true)}>Yes</span>
                                      <span className={(isRecurring) ? "" : "off-active"}
                                            onClick={() => this.toggleRecurring(false)}>No</span>
                                    </span>
																</div>
															</div>
															
															{(isRecurring && (
																<>
                                  <span className="ml-3 mr-3 mb-3">
                                    Note: Each booking will be recorded separately allowing you to cancel any future booking and receive a refund on that booking.
                                  </span>
																	<div className="row food-options w100f mr-3 ml-3">
																		<h6 className="text-center w100 mt-2">
																			{this.printRecurring(startDate, recurring, recurringType, recurringNum)}
																		</h6>
																		<div className="col-8 mt-3 pr-1">
																			<div className="selector">
																				<select className="select"
																				        value={recurringType}
																				        onChange={this.handleChooseRecurringType}>
																					<option value="weekly">Weekly
																					</option>
																					<option value="monthly">Monthly
																					</option>
																				</select>
																			</div>
																		</div>
																		<div className="col-4 mt-3 pl-1">
																			<input type="text" className="form-control"
																			       value={recurringNum}
																			       onChange={(e) => this.setState({recurringNum: e.target.value})}/>
																		</div>
																		<div className="row ml-2 mr-2">
																			{(recurringType === 'monthly') ? (
																				<>
																					<div className="col-12 p-0">
																						<div
																							className="dropdown-item pr-0 pl-0">
																							<input
																								className="form-check-input"
																								type="checkbox"
																								checked={recurring.monthly !== 1}
																								onChange={() => this.handleChooseRecurring(0)}/>
																							<span
																								onClick={() => this.handleChooseRecurring(0)}>{startDate.getDate() + nth(startDate.getDate())} of the month</span>
																						</div>
																					</div>
																					<div className="col-12 p-0">
																						<div
																							className="dropdown-item pr-0 pl-0">
																							<input
																								className="form-check-input"
																								type="checkbox"
																								checked={recurring.monthly === 1}
																								onChange={() => this.handleChooseRecurring(1)}/>
																							<span
																								onClick={() => this.handleChooseRecurring(1)}>{daysInMonth(startDate)} of the month</span>
																						</div>
																					</div>
																				</>
																			) : (
																				week.map((day, i) => {
																					if (excludedDays[i]) return (<></>)
																					return (
																						<div key={day}
																						     className="col-md-3 col-6 p-0">
																							<div
																								className="dropdown-item pr-0 pl-0">
																								<input
																									className="form-check-input"
																									type="checkbox"
																									checked={recurring[day] != null}
																									onChange={() => this.handleChooseRecurring(day)}/>
																								<span
																									onClick={() => this.handleChooseRecurring(day)}>{day}</span>
																							</div>
																						</div>
																					)
																				})
																			)}
																		</div>
																	</div>
																</>
															))}
															
															
															{((space.workspace_type_id === "10" || space.workspace_type_id === "5") && Object.keys(space.amenity).length > 0 && (
																<div className="col-12 pl-3 pr-3 pt-3 border-0">
																	<h5>Select amenities</h5>
																	<div className="row pl-2 pr-2">
																		{(space && space.amenity && Object.keys(space.amenity).map(key => {
																			const item = space.amenity[key];
																			const amenity = printAmenity(item.amenity);
																			if (!amenity) return (<></>)
																			return (
																				<div key={item.amenity_id}
																				     className="col-md-6 col-12 p-0"
																				     onClick={() => this.handleChooseAmenity(item.amenity)}>
																					<div
																						className="dropdown-item pr-0 pl-0">
																						<input
																							id={item.amenity_id + "-checkbox"}
																							name={item.amenity_id + "-checkbox"}
																							className="form-check-input"
																							type="checkbox"
																							checked={selectedAmenities[item.amenity]}
																							onChange={() => this.handleChooseAmenity(item.amenity)}/>
																						<span>{amenity.name}</span>
																					</div>
																				</div>
																			)
																		}))}
																	</div>
																	<span>Notes: Chargeable amenities are payable at the venue on the day of your booking.</span>
																</div>
															))}
														
														</div>
													</div>
													
													{(showCoupon) ? (
														<>
															<h5 className="pb-2">Coupon Code</h5>
															<div className="row">
																<div className="col-12 pl-3">
																	<input id="coupon" name="coupon" type="text"
																	       className="form-control"
																	       placeholder="FM2021"/>
																	<div
																		className={"coupon-btn mr-0 apply-btn " + ((couponLoading) ? 'ld-btn' : '')}
																		onClick={this.checkCouponCode}>
																		{(couponLoading) ? (<><FontAwesomeIcon
																			icon={faCircleNotch}
																			spin={true}/> loading</>) : 'Apply'}
																	</div>
																</div>
															</div>
														</>
													) : (
														<button className="custom-btn clear-btn couponbtn" type="button"
														        onClick={() => this.setState({showCoupon: true})}>Have a
															coupon?</button>
													)}
													
													<div className="space-about mt-3 pt-4 pb-3 booking-prices">
														<h3 className="pb-1">Price details</h3>
														{this.printPriceDetails(space, coupon, couponType, couponPrice, selectedTerm, startDate, startTime, endTime, isRecurring, recurring, recurringType, recurringNum, currency)}
													</div>
													
													{/*<div className="col-12 pr-0 pl-0 mt-3 pt-4 top-b">*/}
													{/*  <h3>Billing info</h3>*/}
													{/*  <div className="row mr-0 ml-0 pt-3">*/}
													
													{/*    <label className="w100f">Card Number</label>*/}
													{/*    <input type="text" className="form-control w100f" placeholder="Card number" required={true} data-paylib="number" />*/}
													{/*    <div className="col-8">*/}
													{/*      <div className="row">*/}
													{/*        <label className="w100f">Expiry Date (MM/YYYY)</label>*/}
													{/*        <div className="col-6 pr-2 pl-0">*/}
													{/*          <input type="text" className="form-control" placeholder="Exp. Month" required={true} data-paylib="expmonth" />*/}
													{/*        </div>*/}
													{/*        <div className="col-6 pr-0 pl-2">*/}
													{/*          <input type="text" className="form-control" placeholder="Exp. Year" required={true} data-paylib="expyear" />*/}
													{/*        </div>*/}
													{/*      </div>*/}
													{/*    </div>*/}
													{/*    <div className="col-4 pr-0">*/}
													{/*      <label className="w100f">Security Code</label>*/}
													{/*      <input type="text" className="form-control" placeholder="CVV" required={true} data-paylib="cvv" />*/}
													{/*    </div>*/}
													{/*    <div id="paymentErrors" className="form-error"></div>*/}
													
													{/*  </div>*/}
													{/*</div>*/}
													
													<div className="col-12 pr-0 pl-0 mt-3 pt-4 top-b bottom-b">
														<h3>Billing address</h3>
														<div className="row pt-3">
															<div className="col-6 pr-2">
																{/*<label className="w100f">First name</label>*/}
																<input type="name" id="fname" name="fname"
																       className="form-control" placeholder="First name"
																       defaultValue={(user) ? user.first_name : ''}
																       required={true}/>
																{(errors.fName) ? (<div
																	className="form-error">{errors.fName}</div>) : (<></>)}
															</div>
															<div className="col-6 pl-2">
																{/*<label className="w100f">Last name</label>*/}
																<input type="name" id="lname" name="lname"
																       className="form-control" placeholder="Last name"
																       defaultValue={(user) ? user.last_name : ''}
																       required={true}/>
																{(errors.lName) ? (<div
																	className="form-error">{errors.lName}</div>) : (<></>)}
															</div>
															<div className="col-12">
																{/*<label className="w100f">Phone number</label>*/}
																<div className="w-100">
																	<PhoneInput
																		preferredCountries={['ae']}
																		country={(user && user.country) ? getCountryCode(user.country)[2] : 'ae'}
																		enableAreaCodes={true}
																		buttonStyle={{
																			borderRadius: '16px 0 0 16px',
																			backgroundColor: '#f9f9f9',
																			zIndex: 10,
																			border: '1px solid #cfd8e7',
																		}}
																		dropdownStyle={{
																			borderRadius: '16px',
																			zIndex: 10,
																			border: '1px solid #cfd8e7',
																		}}
																		searchClass={"form-control-parent"}
																		inputProps={{
																			id: 'phone',
																			name: 'phone',
																			required: true
																		}}
																		inputStyle={{
																			width: '100%',
																			borderRadius: '16px',
																		}}
																		inputClass={'bg-grey-focus-white'}
																		autoFormat={true}
																		value={(user) ? user.phone : ''}
																		enableSearch={true}
																		onChange={(phone, data) => this.setState({countryCode: data.dialCode})}
																	/>
																</div>
																{(errors.phone) ? (<div
																	className="form-error">{errors.phone}</div>) : (<></>)}
															</div>
															<div className="col-12">
																{/*<label className="w100f">Email address</label>*/}
																<input type="email" id="email" name="email"
																       className="form-control"
																       placeholder="Email address"
																       defaultValue={(user) ? user.email : ''}
																       required={true}/>
																{(errors.email) ? (<div
																	className="form-error">{errors.email}</div>) : (<></>)}
															</div>
															<div className="col-6 pr-2">
																{/*<label className="w100f">Country</label>*/}
																<div className="selector">
																	<CountryDropdown classes="select"
																	                 defaultOptionLabel='Country'
																	                 value={selectedCountry}
																	                 onChange={(val) => this.setState({
																		                 selectedCountry: val,
																		                 selectedCity: ''
																	                 })}/>
																</div>
																{(errors.country) ? (<div
																	className="form-error">{errors.country}</div>) : (<></>)}
															</div>
															<div className="col-6 pl-2">
																{/*<label className="w100f">City</label>*/}
																<div className="selector">
																	<RegionDropdown classes="select"
																	                disableWhenEmpty={true}
																	                defaultOptionLabel='City'
																	                country={selectedCountry}
																	                value={selectedCity}
																	                onChange={(val) => this.setState({selectedCity: val})}/>
																</div>
																{(errors.city) ? (<div
																	className="form-error">{errors.city}</div>) : (<></>)}
															</div>
														</div>
													</div>
													
													<FMPayment/>
												</>
											))}
											
											
											{(term === 'catered' && (
												<div className="form-content">
													{(page === 'event') ? (
														<>
															<div className="space-about pt-3 pb-0 border-0">
																<h3 className="pb-3">Event info</h3>
																<div className="row mb-2">
																	
																	{(generic && (
																		<div className="col-12 pr-3 pl-3 pt-3">
																			<h5 className="pb-2">City</h5>
																			<div className="selector">
																				<select name="event_city"
																				        id="event_city"
																				        className="select"
																				        defaultValue={this.props.location?.city ? this.props.location.city : request.eventCity}>
																					{this.props.location?.city ? <option value={this.props.location?.city}>{this.props.location?.city}</option> :
																					<option value="">Select City
																					</option>}
																					{/*<option value="Dubai">Dubai</option>
																					<option value="Abu Dhabi">Abu
																						Dhabi
																					</option>
																					<option value="Sharjah">Sharjah
																					</option>
																					<option value="Ajman">Ajman</option>
																					<option value="Fujairah">Fujairah
																					</option>
																					<option value="Ras Al Khaimah">Ras
																						Al Khaimah
																					</option>
																					<option value="Umm Al Quwain">Umm Al
																						Quwain
																					</option>*/}
																					{this.state.loadingSelecCities ? <option>loading...</option> : this.state.selectCities.filter(c => c !== this.props.location?.city).map(city => <option value={city}>
																						{city}
																					</option>)}
																				</select>
																			</div>
																			{(errors.eventCity) ? (<div
																				className="form-error">{errors.eventCity}</div>) : (<></>)}
																		</div>
																	))}
																	
																	<div className="col-12 pr-3 pl-3 pt-3">
																		<h5 className="pb-2">Type</h5>
																		<div className="selector">
																			<select name="event_type" id="event_type"
																			        className="select"
																			        defaultValue={request.eventType}>
																				<option value="">Please choose your
																					event type
																				</option>
																				<optgroup label="Corporate">
																					<option
																						value="Company/ Business meeting">Company/
																						Business meeting
																					</option>
																					<option
																						value="Company Team Building">Company
																						Team Building
																					</option>
																					<option
																						value="Company/ Business Lunch">Company/
																						Business Lunch
																					</option>
																					<option
																						value="Company/ Business Dinner">Company/
																						Business Dinner
																					</option>
																					<option
																						value="Training Session(s)">Training
																						Session(s)
																					</option>
																					<option
																						value="Conference">Conference
																					</option>
																					<option
																						value="Product(s) Launch">Product(s)
																						Launch
																					</option>
																				</optgroup>
																				<optgroup label="Social">
																					<option
																						value="Anniversary">Anniversary
																					</option>
																					<option value="Wedding">Wedding
																					</option>
																					<option value="Birthday">Birthday
																					</option>
																					<option value="Baby Shower">Baby
																						Shower
																					</option>
																					<option
																						value="Family/ Friends Lunch">Family/
																						Friends Lunch
																					</option>
																					<option
																						value="Family/ Friends Dinner">Family/
																						Friends Dinner
																					</option>
																				</optgroup>
																				<optgroup label="Other">
																					<option value="Other events">Other
																						events
																					</option>
																				</optgroup>
																			</select>
																		</div>
																		{(errors.eventType) ? (<div
																			className="form-error">{errors.eventType} {window.scrollTo({top: 200, left: 0, behavior: 'smooth'})}</div>) : (<></>)}
																	</div>
																	
																	<div className="col-12 pt-3 pr-3 pl-3">
																		<h5 className="pb-1">Brief explanation</h5>
																		<textarea id="event_desc" name="event_desc"
																		          className="form-control"
																		          placeholder="Message"
																		          defaultValue={request.eventDesc}
																		          multiline={true}/>
																		{(errors.eventDesc) ? (<div
																			className="form-error">{errors.eventDesc} {window.scrollTo({top: 200, left: 0, behavior: 'smooth'})}</div>) : (<></>)}
																	</div>
																	
																	{(generic && (
																		<div className="col-12 pt-3 pr-3 pl-3">
																			<h5 className="pb-3">Venue type</h5>
																			<div className="row pb-2">
																				{venues.map(item => (
																					<div key={item}
																					     className="select-option col-lg-6 col-12">
																						<input
																							className="form-check-input check-input"
																							type="checkbox"
																							checked={request.selectedVenues && request.selectedVenues[item] != null}
																							onChange={() => this.handleChooseVenue(item)}
																						/>
																						<span className="opt-name"
																						      onClick={() => this.handleChooseVenue(item)}>{item}</span>
																					</div>
																				))}
																			</div>
																			{(errors.eventVeneus) ? (<div
																				className="form-error">{errors.eventVeneus}</div>) : (<></>)}
																		</div>
																	))}
																	
																	<div className="col-6 pr-3 pl-3 pt-3 pr-mobile-1">
																		<h5 className="pb-1">Start Date</h5>
																		<div className="selector date-picker">
																			<FontAwesomeIcon className="mobile-hide"
																			                 icon={faCalendar}
																			                 style={{
																				                 width: 24,
																				                 height: 24,
																				                 color: '#989898',
																				                 position: 'absolute',
																				                 top: 15,
																				                 right: 15,
																			                 }}/>
																			<div
																				className="dates-picked">{(startDate && (startDate.toLocaleDateString("en-US", dateOptions)))}</div>
																			<DatePicker
																				onFocus={(e) => e.target.readOnly = true}
																				onChangeRaw={this.handleDateChangeRaw}
																				excludeDates={[new Date(), ...Object.keys(excludedDates).map(date => new Date(date))]}
																				filterDate={(date) => excludedDays[date.getDay()] == null}
																				startDate={startDate}
																				endDate={endDate}
																				selectsRange
																				shouldCloseOnSelect={false}
																				minDate={new Date()}
																				onChange={this.updateDates}
																				disabledNavigation/>
																		</div>
																		{(errors.startDate) ? (<div
																			className="form-error">{errors.startDate} {window.scrollTo({top: 200, left: 0, behavior: 'smooth'})}</div>) : (<></>)}
																	</div>
																	
																	<div className="col-6 pl-3 pr-3 pt-3 pl-mobile-1">
																		<h5 className="pb-1">End Date</h5>
																		<div className="selector date-picker">
																			<FontAwesomeIcon className="mobile-hide"
																			                 icon={faCalendar}
																			                 style={{
																				                 width: 24,
																				                 height: 24,
																				                 color: '#989898',
																				                 position: 'absolute',
																				                 top: 15,
																				                 right: 15,
																			                 }}/>
																			<div
																				className="dates-picked">{(endDate && (endDate.toLocaleDateString("en-US", dateOptions)))}</div>
																			<DatePicker
																				onFocus={(e) => e.target.readOnly = true}
																				onChangeRaw={this.handleDateChangeRaw}
																				excludeDates={Object.keys(excludedDates).map(date => new Date(date))}
																				filterDate={(date) => excludedDays[date.getDay()] == null}
																				startDate={startDate}
																				endDate={endDate}
																				shouldCloseOnSelect={true}
																				minDate={startDate}
																				onChange={this.updateEndDate}
																				disabledNavigation/>
																		</div>
																		{(errors.endDate) ? (<div
																			className="form-error">{errors.endDate} {window.scrollTo({top: 200, left: 0, behavior: 'smooth'})}</div>) : (<></>)}
																	</div>
																	
																	<div
																		className="col-12 mt-2 display-flex more-dates border-0">
																		<div className="title-col">
																			<h6 className="mt-2">Flexible dates</h6>
																		</div>
																		<div className="on-off-col">
                                    <span className="on-off">
                                      <span className={(flexibleDates) ? "on-active" : ""}
                                            onClick={() => this.setState({flexibleDates: true})}>Yes</span>
                                      <span className={(flexibleDates) ? "" : "off-active"}
                                            onClick={() => this.setState({flexibleDates: false})}>No</span>
                                    </span>
																		</div>
																	</div>
																
																</div>
															</div>
														</>
													) : (page === 'event-room') ? (
														<div className="space-about pt-3 pb-2 border-0">
															<h3>Event
																space {(days.length > 1) ? '(Day ' + (dayIndex + 1) + ')' : ''}</h3>
															
															{(copyAlert <= dayIndex && dayIndex > 0 && (
																<div className="blue-alert fz-17">
																	<div className="col-alert">
																		<div className="alert-title fz-17">Copy
																			Day {dayIndex} items into
																			Day {dayIndex + 1}</div>
																		<div className="alert-subtitle fz-17">Note: you
																			can change what you copied over afterwards.
																		</div>
																	</div>
																	<div className="col-btn">
																		<button type="button"
																		        className="btn btn-primary fl_btn"
																		        onClick={this.handleCopyDay}>Copy
																		</button>
																	</div>
																</div>
															))}
															
															<div className="row pt-3 mb-2">
																
																<div className="col-12 pl-3 pr-3 pt-2 pt-3 border-0">
																	<h5>Select layout</h5>
																	<div className="row pl-2 pr-2">
																		
																		{(generic) ? (
																			(layouts.map(layout => (
																				<div key={layout.id}
																				     className="layout-col">
																					<div
																						className={"space-layout " + ((days[dayIndex].selectedLayout === layout.id) ? 'options-selected' : '')}
																						onClick={() => this.handleChooseLayout(dayIndex, layout.id, layout.name)}>
																						<img
																							src={getLayoutImg(layout.id)}/>
																						<div
																							className="layout-count">{layout.name}</div>
																					</div>
																				</div>
																			)))
																		) : (space && space.layouts && Object.keys(space.layouts).length > 0 && (
																			Object.keys(space.layouts).map(key => (
																				<div key={key} className="layout-col">
																					<div
																						className={"space-layout " + ((days[dayIndex].selectedLayout === key) ? 'options-selected' : '')}
																						onClick={() => this.handleChooseLayout(dayIndex, key, space.layouts[key])}>
																						<img src={getLayoutImg(key)}/>
																						<div
																							className="layout-count">{space.layouts[key]}</div>
																					</div>
																				</div>
																			))
																		))}
																	</div>
																</div>
																{(errors.selectedLayout) ? (<div
																	className="col-12 form-error mt-2">{errors.selectedLayout} {window.scrollTo({top: 200, left: 0, behavior: 'smooth'})}</div>) : (<></>)}
																
																<div className="col-12 more-counter mb-2 border-0">
																	<h5 className="capacity-title">Capacity</h5>
																	<span id="count" className="counter">
                                    <input type="number" className="form-control" placeholder="1"
                                           value={days[dayIndex].capacity}
                                           onChange={(e) => this.handleUpdateDayCapacity(e, dayIndex)}
										   onWheel={ event => event.currentTarget.blur() }
										   />
                                  </span>
																</div>
																{(errors.capacity) ? (<div
																	className="col-12 form-error mt-0">{errors.capacity} {window.scrollTo({top: 200, left: 0, behavior: 'smooth'})}</div>) : (<></>)}
																
																<div className="col-6 pr-mobile-1 pt-2">
																	<h5 className="pb-2">Start time</h5>
																	<div className="selector">
																		<select name="start_time" id="start_time"
																		        className="select"
																		        value={days[dayIndex].startTime}
																		        onChange={this.onDayStartTimeChange}>
																			{this.printTimeOptions(space, 'hourly', startDate, 'start', days[dayIndex].startTime)}
																		</select>
																	</div>
																	{(errors.startTime) ? (<div
																		className="form-error">{errors.startTime}</div>) : (<></>)}
																</div>
																<div className="col-6 pl-mobile-1 pt-2">
																	<h5 className="pb-2">End time</h5>
																	<div className="selector">
																		<select name="end_time" id="end_time"
																		        className="select"
																		        value={days[dayIndex].endTime}
																		        onChange={this.onDayEndTimeChange}>
																			{this.printTimeOptions(space, 'hourly', startDate, 'end', days[dayIndex].startTime)}
																		</select>
																	</div>
																	{(errors.endTime) ? (<div
																		className="form-error">{errors.endTime}</div>) : (<></>)}
																</div>
																
																<div className="col-12 pl-3 pr-3 pt-3 pb-3 border-0">
																	<h5>Select food & beverages</h5>
																	<div className="row pl-2 pr-2">
																		
																		{foodMenu.map(item => {
																				const selectedFood = days[dayIndex].selectedFood[item.key];
																				return (
																					<div key={item.key}
																					     className="col-12 p-0">
																						<div
																							className="dropdown-item pr-0 pl-0">
																							<input
																								className="form-check-input"
																								type="checkbox"
																								checked={selectedFood != null}
																								onChange={() => this.handleChooseDayFood(dayIndex, item.key)}/>
																							<span
																								onClick={() => this.handleChooseDayFood(dayIndex, item.key)}>{item.name}</span>
																						</div>
																						{(selectedFood != null && (
																							<div
																								className="row food-options">
																								{(item.options && (
																									<>
																										{item.options.map(opt => (
																											<div
																												key={opt.name}
																												className="food-col"
																												onClick={() => this.handleChooseDayFoodOption(dayIndex, item.key, 'option', opt.name)}>
																												<div
																													className={"food-layout " + ((selectedFood.selectedOption === opt.name) ? 'food-act' : '')}>
																													{opt.name}
																												</div>
																											</div>
																										))}
																										{(errors[item.key + "Option"] && (
																											<div
																												className="form-error mt-0">{errors[item.key + "Option"]}</div>))}
																										{((selectedFood.selectedOption === 'Other' || selectedFood.selectedOption === 'Tea & coffee with other') && (
																											<>
																												<h6 className="w100f mt-2 p-2">Description</h6>
																												<textarea
																													id={item.key + "_opt_other"}
																													name={item.key + "_opt_other"}
																													className="form-control mb-0"
																													multiline={true}/>
																												{(errors[item.key + "Other"] && (
																													<div
																														className="form-error mt-0">{errors[item.key + "Other"]}</div>))}
																											</>
																										))}
																									</>
																								))}
																								{(item.type && (
																									<>
																										<h6 className="w100f font-weight-bold p-2">Food
																											type</h6>
																										{item.type.map(opt => (
																											<div
																												key={opt.name}
																												className="food-col"
																												onClick={() => this.handleChooseDayFoodOption(dayIndex, item.key, 'type', opt.name)}>
																												<div
																													className={"food-layout " + ((selectedFood.selectedType === opt.name) ? 'food-act' : '')}>
																													{opt.name}
																												</div>
																											</div>
																										))}
																										{(errors[item.key + "Type"] && (
																											<div
																												className="form-error mt-0">{errors[item.key + "Type"]}</div>))}
																									</>
																								))}
																								{(item.lunch && (
																									<>
																										<h6 className="w100f font-weight-bold mt-3 p-2">{(item.key === 'dinner') ? 'Dinner' : 'Lunch'} type</h6>
																										{item.lunch.map(opt => (
																											<div
																												key={opt.name}
																												className="food-col"
																												onClick={() => this.handleChooseDayFoodOption(dayIndex, item.key, 'lunch', opt.name)}>
																												<div
																													className={"food-layout " + ((selectedFood.selectedLunch === opt.name) ? 'food-act' : '')}>
																													{opt.name}
																												</div>
																											</div>
																										))}
																										{(errors[item.key + "Lunch"] && (
																											<div
																												className="form-error mt-0">{errors[item.key + "Lunch"]}</div>))}
																										{(selectedFood.selectedLunch === 'Other' && (
																											<>
																												<h6 className="w100f mt-2 p-2">Description</h6>
																												<textarea
																													id={item.key + "_lunch_other"}
																													name={item.key + "_lunch_other"}
																													className="form-control mb-0"
																													multiline={true}/>
																												{(errors[item.key + "LunchOther"] && (
																													<div
																														className="form-error mt-0">{errors[item.key + "LunchOther"]}</div>))}
																											</>
																										))}
																									</>
																								))}
																								{(item.drinks && (
																									<>
																										<h6 className="w100f font-weight-bold mt-3 p-2">Drinks</h6>
																										{item.drinks.map(opt => (
																											<div
																												key={opt.name}
																												className="food-col"
																												onClick={() => this.handleChooseDayFoodOption(dayIndex, item.key, 'drinks', opt.name)}>
																												<div
																													className={"food-layout " + ((selectedFood.selectedDrink === opt.name) ? 'food-act' : '')}>
																													{opt.name}
																												</div>
																											</div>
																										))}
																										{(errors[item.key + "Drink"] && (
																											<div
																												className="form-error mt-0">{errors[item.key + "Drink"]}</div>))}
																										{(selectedFood.selectedDrink === 'Other' && (
																											<>
																												<h6 className="w100f mt-2 p-2">Description</h6>
																												<textarea
																													id={item.key + "_drinks_other"}
																													name={item.key + "_drinks_other"}
																													className="form-control mb-0"
																													multiline={true}/>
																												{(errors[item.key + "DrinkOther"] && (
																													<div
																														className="form-error mt-0">{errors[item.key + "DrinkOther"]}</div>))}
																											</>
																										))}
																									</>
																								))}
																								{(item.key === 'drinks' && (
																									<>
																										<h6 className="w100f mt-2 p-2">Description</h6>
																										<textarea
																											id={item.key + "_other"}
																											name={item.key + "_other"}
																											className="form-control mb-0"
																											multiline={true}/>
																										{(errors[item.key + "Other"] && (
																											<div
																												className="form-error mt-0">{errors[item.key + "Other"]}</div>))}
																									</>
																								))}
																							
																							</div>
																						))}
																					</div>
																				)
																			}
																		)}
																	</div>
																</div>
																
																<div className="col-12 pl-3 pr-3 pt-3 border-0">
																	<h5>Select amenities</h5>
																	<div className="row pl-2 pr-2">
																		<div className="col-12 p-0">
																			{(generic) ? (
																				(amenities.map(item => {
																					const amenity = printAmenity(item);
																					if (!amenity) return (<></>)
																					return (
																						<div key={item}
																						     className="dropdown-item opt pr-0 pl-0">
																							<input
																								className="form-check-input check-input"
																								type="checkbox"
																								checked={days[dayIndex].selectedAmenities[item] != null}
																								onChange={() => this.handleChooseDayAmenity(null, dayIndex, item)}/>
																							<span className="opt-name"
																							      onClick={() => this.handleChooseDayAmenity(null, dayIndex, item)}>
                                              {amenity.name}
                                            </span>
																							{(amenity.name !== "Wifi" && days[dayIndex].selectedAmenities[item] != null && (
																								<div id="count"
																								     className="input-col">
																									<input type="number"
																									       className="form-control"
																									       placeholder="1"
																									       value={days[dayIndex].selectedAmenities[item]}
																									       onChange={(e) => this.handleChooseDayAmenity(e, dayIndex, item)}
																										   onWheel={ event => event.currentTarget.blur() }
																										   />
																								</div>
																							))}
																						</div>
																					)
																				}))
																			) : (space && space.amenity && Object.keys(space.amenity).map(key => {
																				const item = space.amenity[key];
																				const amenity = printAmenity(item.amenity);
																				if (!amenity) return (<></>)
																				return (
																					<div key={key}
																					     className="dropdown-item opt pr-0 pl-0">
																						<input
																							className="form-check-input check-input"
																							type="checkbox"
																							checked={days[dayIndex].selectedAmenities[item.amenity] != null}
																							onChange={() => this.handleChooseDayAmenity(null, dayIndex, item.amenity)}/>
																						<span className="opt-name"
																						      onClick={() => this.handleChooseDayAmenity(null, dayIndex, item.amenity)}>
                                              {amenity.name}
                                            </span>
																						{(amenity.name !== "Wifi" && days[dayIndex].selectedAmenities[item.amenity] != null && (
																							<div id="count"
																							     className="input-col">
																								<input type="number"
																								       className="form-control"
																								       placeholder="1"
																								       value={days[dayIndex].selectedAmenities[item.amenity]}
																								       onChange={(e) => this.handleChooseDayAmenity(e, dayIndex, item.amenity)}
																									   onWheel={ event => event.currentTarget.blur() }
																									   />
																							</div>
																						))}
																					</div>
																				)
																			}))}
																		</div>
																	</div>
																</div>
															
															</div>
														</div>
													) : (page === 'accommodation') ? (
														<div className="space-about pt-3 border-0">
															<h3>Accommodation</h3>
															
															<div className="row mb-2">
																
																<div
																	className="col-12 mt-4 display-flex more-dates border-0">
																	<div className="title-col">
																		<h6 className="mt-2">Accommodation (sleeping
																			rooms) required</h6>
																	</div>
																	<div className="on-off-col">
                                    <span className="on-off">
                                      <span className={(accomReq) ? "on-active" : ""}
                                            onClick={() => this.toggleAccommodation(true)}>Yes</span>
                                      <span className={(accomReq) ? "" : "off-active"}
                                            onClick={() => this.toggleAccommodation(false)}>No</span>
                                    </span>
																	</div>
																</div>
																
																{(accomReq && (
																	<>
																		<div className="col-6 pr-3 pl-3 pt-3">
																			<h5 className="pb-1">Check-in</h5>
																			<div className="selector date-picker">
																				{/*<FontAwesomeIcon icon={faCalendar}*/}
																				{/*                 style={{width: 24, height: 24, color: '#989898', position: 'absolute', top: 15, right: 15, }} />*/}
																				<div
																					className="dates-picked">{(accomStartDate && (accomStartDate.toLocaleDateString("en-US", dateOptions)))}</div>
																				<DatePicker
																					onFocus={(e) => e.target.readOnly = true}
																					onChangeRaw={this.handleDateChangeRaw}
																					excludeDates={Object.keys(excludedDates).map(date => new Date(date))}
																					filterDate={(date) => excludedDays[date.getDay()] == null}
																					startDate={accomStartDate}
																					endDate={accomEndDate}
																					selectsRange
																					shouldCloseOnSelect={false}
																					minDate={new Date()}
																					onChange={this.updateAccomDates}
																					disabledNavigation/>
																			</div>
																		</div>
																		
																		<div className="col-6 pr-3 pl-3 pt-3">
																			<h5 className="pb-1">Check-out</h5>
																			<div className="selector date-picker">
																				{/*<FontAwesomeIcon icon={faCalendar}*/}
																				{/*                 style={{width: 24, height: 24, color: '#989898', position: 'absolute', top: 15, right: 15, }} />*/}
																				<div
																					className="dates-picked">{(accomEndDate && (accomEndDate.toLocaleDateString("en-US", dateOptions)))}</div>
																				<DatePicker
																					onFocus={(e) => e.target.readOnly = true}
																					onChangeRaw={this.handleDateChangeRaw}
																					excludeDates={Object.keys(excludedDates).map(date => new Date(date))}
																					filterDate={(date) => excludedDays[date.getDay()] == null}
																					startDate={accomStartDate}
																					endDate={accomEndDate}
																					shouldCloseOnSelect={true}
																					minDate={addDays(accomStartDate, 1)}
																					onChange={this.updateAccomEndDate}
																					disabledNavigation/>
																			</div>
																		</div>
																		
																		{(rooms.length > 0 && (
																			<div
																				className="col-12 pl-3 pr-3 pt-3 border-0">
																				<h5>Select rooms</h5>
																				<div className="row pl-2 pr-2">
																					<div className="col-12 p-0">
																						{this.printRooms(rooms, accomStartDate, accomEndDate, errors)}
																					</div>
																				</div>
																			</div>
																		))}
																	</>
																))}
															
															</div>
														</div>
													) : (page === 'price') ? (
														<div className="space-about pt-3 border-0">
															<h3>Price</h3>
															
															<div className="row mb-2">
																
																<h5 className="w100f ml-3 mr-3 mt-3 mb-2">Approximate
																	budget</h5>
																
																<div className="col-6 pr-1">
																	<div className="selector">
																		<select name="price_type" id="price_type"
																		        className="select">
																			<option value="total">Total</option>
																			<option value="person">Per person</option>
																		</select>
																	</div>
																</div>
																<div className="col-6 pl-1">
																	<div className="selector">
																		<select name="currency" id="currency"
																		        className="select"
																		        defaultValue={request.currency}>
																			{currencyList.map(item => (
																				<option
																					value={item.name}>{item.name}</option>
																			))}
																		</select>
																	</div>
																</div>
																<div className="col-12">
																	<input id="price" 
																		name="price" 
																		type="number"
																		className="form-control"
																		onWheel={ event => event.currentTarget.blur() }
																	/>
																</div>
																
																{(!generic && (
																	<div
																		className="col-12 mt-3 display-flex more-dates border-0">
																		<div className="title-col">
																			<h6 className="mt-2">Send request to other
																				similar venues</h6>
																		</div>
																		<div className="on-off-col">
                                    <span className="on-off">
                                      <span className={(sendToOthers) ? "on-active" : ""}
                                            onClick={() => this.setState({sendToOthers: true})}>Yes</span>
                                      <span className={(sendToOthers) ? "" : "off-active"}
                                            onClick={() => this.setState({sendToOthers: false})}>No</span>
                                    </span>
																		</div>
																	</div>
																))}
																
																{/*<div className="col-12 mt-3 display-flex more-dates border-0">*/}
																{/*  <div className="title-col">*/}
																{/*    <h6 className="mt-2">Allow venues to directly contact me</h6>*/}
																{/*  </div>*/}
																{/*  <div className="on-off-col">*/}
																{/*    <span className="on-off">*/}
																{/*      <span className={(directContact) ? "on-active" : ""}*/}
																{/*            onClick={() => this.setState({directContact: true})}>Yes</span>*/}
																{/*      <span className={(directContact) ? "" : "off-active"}*/}
																{/*            onClick={() => this.setState({directContact: false})}>No</span>*/}
																{/*    </span>*/}
																{/*  </div>*/}
																{/*</div>*/}
															
															</div>
														</div>
													) : (page === 'summary') ? (
														<div className="space-about pt-4 border-0">
															<h3 className="pb-1">Request Summary</h3>
															{this.printRequestSummary(space, page, request)}
														</div>
													) : (page === 'success') ? (
														<>
															{(generic ? (
																<>
																	<div className="space-about pt-4 border-0">
																		<h3 className="pb-1">Request Sent</h3>
																		<h6>Your request has been sent successfully.
																			Please check the email you provided for
																			updates.</h6>
																	</div>
																</>
															) : (
																<>
																	<div className="space-about pt-4 border-0">
																		<h3 className="pb-1">Request Sent</h3>
																		<h6>Your request has been sent to the selected
																			venue. Please check the email you provided
																			for updates.</h6>
																	</div>
																	<Link
																		to={'/space/' + match.params.id + '?t=' + term}>
																		<button
																			className="btn btn-lg btn-primary submit-btn mb-5">Back
																			to space
																		</button>
																	</Link>
																</>
															))}
														</>
													) : (
														<></>
													)}
												
												</div>
											))}
											
											{((term === 'catered' && page === 'contact') && (
												<div className="space-about pt-3 pb-0 border-0">
													<h3 className="pb-3">Contact info</h3>
													<div className="w100">
														<input type="name" id="fname" name="fname"
														       className="form-control" placeholder="First name"
														       defaultValue={(request.fName) ? request.fName : (user) ? user.first_name : ''}
														       required={true}/>
														{(errors.fName) ? (
															<div className="form-error">{errors.fName}</div>) : (<></>)}
													</div>
													<div className="w100">
														<input type="name" id="lname" name="lname"
														       className="form-control" placeholder="Last name"
														       defaultValue={(request.lName) ? request.lName : (user) ? user.last_name : ''}
														       required={true}/>
														{(errors.lName) ? (
															<div className="form-error">{errors.lName}</div>) : (<></>)}
													</div>
													<div className="w100">
														<input type="email" id="email" name="email"
														       className="form-control" placeholder="Email address"
														       defaultValue={(request.email) ? request.email : (user) ? user.email : ''}
														       required={true}/>
														{(errors.email) ? (
															<div className="form-error">{errors.email}</div>) : (<></>)}
													</div>
													<div className="w100">
														
														<PhoneInput
															preferredCountries={['ae']}
															country={(user && user.country) ? getCountryCode(user.country)[2] : 'ae'}
															enableAreaCodes={true}
															buttonStyle={{
																borderRadius: '16px 0 0 16px',
																backgroundColor: '#f9f9f9',
																zIndex: 10,
																border: '1px solid #cfd8e7',
															}}
															dropdownStyle={{
																borderRadius: '16px',
																zIndex: 10,
																border: '1px solid #cfd8e7',
															}}
															searchClass={"form-control-parent"}
															inputProps={{id: 'phone', name: 'phone', required: true}}
															inputStyle={{
																width: '100%',
																borderRadius: '16px',
															}}
															inputClass={'bg-grey-focus-white'}
															autoFormat={true}
															value={(user) ? user.phone : ''}
															enableSearch={true}
															onChange={(phone, data) => this.setState({countryCode: data.dialCode})}
														/>
														{/*<input type="tel" id="phone" name="phone"*/}
														{/*       className="form-control form-control-phone"*/}
														{/*       placeholder="Phone number"*/}
														{/*       defaultValue={(request.phone) ? request.phone : (user) ? user.phone : ''}*/}
														{/*       required={true}/>*/}
														
														{(errors.phone) ? (
															<div className="form-error">{errors.phone}</div>) : (<></>)}
													</div>
													<div className="w100">
														<input type="text" id="company" name="company"
														       className="form-control" placeholder="Company"
														       defaultValue={(request.company) ? request.company : (user) ? user.company : ''}/>
														{(errors.company) ? (<div
															className="form-error">{errors.company}</div>) : (<></>)}
													</div>
												</div>
											))}
											
											{((term === 'hourly' || page !== 'success') && (
												<div className="w100 mt-4 mb-4 pr-0 pl-0 form-bottom">
													{(term === 'catered') ? (
														<>
															{(page !== 'event' && (
																<button className="custom-btn m-0 pl-0 clear-btn"
																        type="button" onClick={this.handleBackBtn}>
																	<FontAwesomeIcon icon={faAngleLeft} style={{
																		width: 24,
																		height: 24,
																		marginBottom: -4
																	}}/>
																	<span className="back-btn-span">Back</span>
																</button>
															))}
															<button
																className={"custom-btn mr-0 next-btn " + ((reqLoading) ? 'ld-btn' : '')}
																type="submit">
																{((page === 'contact') || page === 'summary') ? (
																	(reqLoading) ? (<><FontAwesomeIcon
																		icon={faCircleNotch}
																		spin={true}/> loading</>) : 'Submit') : 'Next'
																}
															</button>
														</>
													) : (
														<></>
														// <button
														// 	className={"btn btn-lg btn-primary btn-block fl_btn submit-btn " + ((reqLoading) ? 'ld-btn' : '')}>
														// 	{(reqLoading) ? (<><FontAwesomeIcon icon={faCircleNotch}
														// 	                                    spin={true}/> loading</>) : 'Go to payment'}
														// </button>
													)}
												</div>
											))}
										
										</form>
									</div>
								</div>
							</div>
							
							{(reqAlert &&
								<FloatingAlert error={reqAlert} onClick={() => this.setState({reqAlert: null})}/>)}
						
						</div>
					</div>
				</div>
				
				<div id="bottom-cont">
					<Footer smallNav={true} isPayment={true}/>
				</div>
			</>
		);
	}
}

export default connect((state, props) => ({
	defCurrency: state.settings.defCurrency,
}), null)(withRouter(WorkspaceBooking));

